import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as querydata from "../dataService/DataService";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoimage from "../../assets/images/ozen-logo.svg";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import poweredByDigitech from "../../assets/images/powered-by-digitech.png";
// Login Validation
export const loginSchema = Yup.object({
  email: Yup.string().email("Please enter a valid email").required("Please enter your email"),
  password: Yup.string().required("Please enter your password"),
});

const Login = () => {
  // Initialize Navigate method
  const navigate = useNavigate();

  // Values defined for Formik
  const initialValues = {
    email: "",
    password: "",
  };

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  // Build Model and fill data in Formik model
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: (values, action) => {


        loginMutation.mutate(values);

      },
    });

  // Form post
  const loginMutation = useMutation(querydata.login, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },

    onSuccess: (data) => {
      // const userPublicKey = data.otherUserData.user_id;
      const userData = data.otherUserData;
      Cookies.set("user", userData.user_name);
      Cookies.set("token1", data.token);
      navigate("/websites-list");
    },
  });

  const handleForgotPassword = (e) => {
    e.preventDefault();
    navigate("/forgot-password");
  };
  return (
    <div className="login-wrapper position-relative d-flex align-items-center flex-wrap">
      <div className="d-flex justify-content-center flex-wrap w-100">
        <form
          className="w-100 m-auto rounded-2 position-relative z-2 bg-white pb-4"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="login-logo px-3 py-4 text-center">
            <img src={logoimage} alt="Atmosphere" title="Atmosphere" />
            <h4 className="mt-4">Login</h4>
          </div>
          <div className="login-fields px-lg-5 px-4">
            <div className="mb-4">
              <input
                maxLength={70}
                type="email"
                placeholder="Enter email address"
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                id="Email"
                className="form-control shadow-none"
              />
              {errors.email && touched.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </div>

            <div className="position-relative mb-4">
              <input
                maxLength={30}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                required
                id="Password"
                className="form-control shadow-none"
              />
              <span
                className="showpassword position-absolute"
                onClick={toggleShowPassword}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </span>

              {errors.password && touched.password && (
                <div className="text-danger">{errors.password}</div>
              )}
              <div className="text-end mt-2">
                <span className="btn p-0" onClick={handleForgotPassword}>
                  Forgot Password?
                </span>
              </div>
            </div>
            <div className="mb-4">
              {loginMutation.isLoading ? (
                <button
                  className="ms-auto btn btn-primary login-btn btn-theme btn-header rounded-3 w-100 text-nowrap text-uppercase green-btn calculate-btn fw-semibold rounded-3 w-100 opacity-100 spinner-login-btn"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden" role="status">
                    Loading...
                  </span>
                </button>
              ) : (
                <button
                  id="login-btn"
                  type="submit"
                  className="btn btn-primary login-btn btn-theme fw-semibold text-uppercase px-3 py-3 w-100"
                >
                  Login
                </button>
              )}
            </div>
          </div>
        </form>
        <div className="bottom-text ms-auto me-auto position-relative z-1 text-center w-100 mt-4">
          <p className="text-white">
            {/* <strong className="fw-semibold">Please Note:</strong>{" "} */}
            <span>
              This is Beta VER 2.0! We're regularly updating it and actively refining it for optimal performance.
            </span>
          </p>
        </div>
      </div>
      <div className="powered-by-digitech position-absolute end-0 bottom-0 m-3 z-1">
        <img src={poweredByDigitech} alt="" title="" />
      </div>
    </div>
  );
};

export default Login;
