import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from "react-query";
import DeleteCollateralsModal from "./DeleteCollateralsTitleModal"
import { toast } from "react-toastify";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import pdfIcon from "../../assets/images/pdf-doc.svg";
import * as querydata from "../../services/service";
import { getAllCollaterals, updateCollateralsIsActive, updateCollateralSorting } from '../dataService/DataService';
import { useQuery } from 'react-query';
import { Button, Form } from 'react-bootstrap';
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";

import AlbumViewSelectionForProperties from "../manageAlbum/AlbumViewSelectionForProperties";
import { Table } from "../../components/dragTable/Table";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import { faPlus, faTrash, faTrashAlt, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditCollaterals from "./EditCollaterals";

import DeleteCollateralsHead from "./DeleteCollateralsHead";

import DropdownWithDragDrop from "./DropDown";

export const collateralsSchema = Yup.object({
    title: Yup.string().trim().required("Please enter title"),
    description: Yup.string().trim().required("Please enter description"),
});
export const headSchema = Yup.object({
    head: Yup.string().trim().required("Please enter heading"),
})
const ManageCollaterals = () => {
    const queryClient = useQueryClient();

    const [isLoading, setIsLoading] = useState(false);
    let allCollaterals = useQuery({ queryKey: "getAllCollaterals", queryFn: getAllCollaterals, refetchOnWindowFocus: false });
    const headData = allCollaterals?.data?.data;
    const [editId, setEditId] = useState("");
    const [unitPlanPdf, setUnitPlanPdf] = useState(null);
    const [singleCropImageProp, setSingleCropImageProp] = useState(null);
    const [singleFileProperties, setSingleFileProperties] = useState(null);
    const [cropMediaModal, setCropMediaModal] = useState(false);
    const closeCropMediaModal = () => setCropMediaModal(false);
    const [settingValue, setSettingValue] = useState("")
    const [folderId, setFolderId] = useState("");
    const [albumId, setAlbumId] = useState("");
    const [websiteId, setwebsiteId] = useState("");
    const [collateralpdf, setCollateralPdf] = useState(null);
    const [checkMultipleImageSection, setMultipleImageSection] = useState("");
    const [currentFileId, setCurrentFileId] = useState("");
    const [maincroppedFile, setmainCroppedFile] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showMessages, setMessages] = useState("");
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [selectedRowNameData, setSelectedRowNameData] = useState({});
    const [showDelModal, setShowDelModal] = useState(false);
    const [showDelHeadModal, setShowDelHeadModal] = useState(false);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [editColDetail, setEditColDetail] = useState("");
    const [open, setOpen] = useState(false);
    const [editName, setEditName] = useState("");
    const [mediaType, setMediaType] = useState("");
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);
    const [actions, setAction] = useState();
    const initialValuesForHead = {
        head: ""
    }

    useEffect(() => {
        setValues2({ head: editName ? editName : "" });
    }, [headData, editName]);
    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setShowEdit(false);
        setValues1({ head: "" });
        setValues2({ head: "" });
        setTouched1({});


    };
    const handelHeading = (e, headId) => {
        e.preventDefault();

        setShowEdit(true);
        setEditId(headId);

        const collateral = headData?.find(item => item.head_id === headId);

        setEditName(collateral ? collateral.collateral_name : '');

    }


    const handleRemoveTab = (e, headId) => {
        e.preventDefault();

        setShowDelHeadModal(true);
        setSelectedRowData(headId);
    };

    const handleMainCroppedImage = (e) => {
        setmainCroppedFile(null);
        if (e) {
            setCollateralPdf(null);
        }
    }

    const showCropMediaModal = (id, val, index) => {
        setCurrentFileId(id);

        if (val === "secondImage") {
            setMultipleImageSection(val);

            setSettingValue("1/1");
        }




        setCropMediaModal(true);
    };



    const {
        values: values1,
        setValues: setValues1,
        handleBlur: handleBlur1,
        handleChange: handleChange1,
        handleSubmit: handleSubmit1,
        errors: errors1,
        touched: touched1,
        setTouched: setTouched1,
        setFieldValue: setFieldValue1,
        setErrors: setErrors1
    } = useFormik({
        initialValues: initialValuesForHead,
        validationSchema: headSchema,
        onSubmit: (values, action) => {

            addnewprojectMutation.mutate(values);

        }
    })

    const addnewprojectMutation = useMutation(querydata.insertCollateralHead, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setShow(false);
            toast.dismiss();
            toast.success(data.message);
            setValues1({
                head: ""
            });

            setIsLoading(false);
            setTouched1({});
            queryClient.invalidateQueries("getAllCollaterals");

        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });
    const {
        values: values2,

        handleChange: handleChange2,
        handleSubmit: handleSubmit2,
        errors: errors2,
        touched: touched2,
        setValues: setValues2,
        setFieldValue: setFieldValue2
    } = useFormik({
        initialValues: initialValuesForHead,
        validationSchema: headSchema,
        onSubmit: (values, action) => {


            editprojectMutation.mutate({ editId, values });

        }
    })


    const editprojectMutation = useMutation(querydata.editCollateralsHead, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);
            toast.dismiss();
            toast.success(data.message);
            setShowEdit(false);
            queryClient.invalidateQueries("getAllCollaterals");

        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });


    const initialValues = {
        title: "",
        description: ""
    }
    const [selectedCollateral, setSelectedCollateral] = useState(null);
    const head = headData?.find(item => item.head_id === selectedCollateral);

    const heading = head ? head.collateral_name : '';

    const handleSelectOption = (id) => {

        setSelectedCollateral(id);
        setOpen(false);
        setEditId(id);
    };

    const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        setTouched,
        setValues
    } = useFormik({
        initialValues: initialValues,
        validationSchema: collateralsSchema,
        onSubmit: (values, action) => {

            const formData = new FormData();
            if (heading === null || heading === "") {

                toast.dismiss();
                toast.error("Please add head first");

                return;
            } else {
                formData.append("heading", heading);
            }

            formData.append("title", values.title);
            formData.append("description", values.description);
            formData.append("colHeadId", selectedCollateral);
            if (collateralpdf === null || collateralpdf === false) {

                toast.dismiss();
                toast.error("Please upload a pdf");

                return;
            } else {
                formData.append("pdflink", collateralpdf);
            }
            if (actions === "PREVIEW") {
                preview.mutate(formData);
            }
            if (actions === "SAVE") {
                insertMutation.mutate(formData);
            }

        }
    })
    const insertMutation = useMutation(querydata.insertCollateral, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);
            toast.dismiss();
            toast.success(data.message);
            closeMessageModal();
            setCollateralPdf(null);
            setmainCroppedFile("");
            setValues({ title: "", description: "" })
            setTouched({});
            queryClient.invalidateQueries("getAllCollaterals");
        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });
    const preview = useMutation(querydata.previewCollateral, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsPreviewLoading(false);
            const websiteUrl = `${data.previewURL}?iscollateralspreview=true&uid=${data.encryptedUserId}`;
            window.open(websiteUrl, "_blank");

        },
        onSettled: () => {
            setIsPreviewLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsPreviewLoading(true); // Set loading to true before making the request
        },
    });
    const handleViewMessageModal = (message) => {

        setMessages(message);

        setShowMessageModal(true);
    }
    const closeMessageModal = () => {
        setShowMessageModal(false);
        setTouched({});
        setValues({ title: "", description: "" })
        setCollateralPdf(null);
    }


    const closeEditModal = () => {
        setShowEditModal(false);
    }
    const handleShowDelModal = (row) => {

        setSelectedRowData(row.original.id);
        setSelectedRowNameData(row.original.title);
        setShowDelModal(true);
    }
    const handleCloseDelModal = () => {
        setShowDelModal(false);
        setShowDelHeadModal(false);
    }
    const handleNavigateToEdit = (collateralId) => {

        setEditColDetail(collateralId);
        setShowEditModal(true);
    }

    const [data, setData] = useState([]);
    const handleStatus = (e, row) => {

        const status = e.target.value;
        const id = row.original.id;
        row.original.status = status;

        const data = changeApplicationStatusMutate.mutate({ id, status });
    };

    const changeApplicationStatusMutate = useMutation(updateCollateralsIsActive, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess(data) {
            toast.dismiss();
            toast.success(data.message);
        },
    });
    const columns = React.useMemo(
        () => [
            { Header: "Sr. No", accessor: "srNo" },
            {
                Header: "Title",
                accessor: "title"
            },
            {
                Header: "Description",
                accessor: "description"
            },
            {
                Header: "PDF",
                accessor: "pdf_url",
                Cell: ({ cell }) => (
                    <Link

                        onClick={() => window.open(cell.value)}
                    >
                        Open PDF
                    </Link>
                )
            },
            {
                Header: "Status",
                Cell: ({ row }) => (
                    <select
                        onChange={(e) => handleStatus(e, row)}
                        value={row.original.status}
                        className="form-select"
                        style={{ width: "120px" }}
                    >
                        <option key="1" value="1">
                            Active
                        </option>
                        <option key="0" value="0">
                            Inactive
                        </option>
                    </select>
                ),
            },

            {
                Header: "Actions",
                Cell: ({ row }) => (
                    <>
                        <button
                            onClick={() =>
                                handleNavigateToEdit(row.original.id)
                            }
                            className="btn btn-primary btn-sm btn-theme"
                        >
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                        <button
                            variant="info"
                            onClick={() => handleShowDelModal(row)}
                            className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
                        >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </>
                ),
            },
        ],
        []
    );


    useEffect(() => {
        if (allCollaterals?.data?.data) {
            setData(allCollaterals?.data?.data);
        }
    }, [allCollaterals]);
    // useEffect(() => {

    //     setSelectedCollateral(headData?.[0]?.head_id);

    // }, [headData]);

    const handleReorderSubSections = (dragIndex, hoverIndex) => {
        const dragSubSection = data[dragIndex];
        const updatedSubSections = [...data];
        updatedSubSections.splice(dragIndex, 1);
        updatedSubSections.splice(hoverIndex, 0, dragSubSection);

        setData(updatedSubSections);
    };


    const changeSortingIndexMutation = useMutation(querydata.updateCollateralHeadSorting, {
        // Configuration options for the mutation
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);
            toast.dismiss();
            toast.success(data.message);
            queryClient.invalidateQueries("getAllCollaterals");
        },
        onSettled: () => {
            setIsLoading(false);
        },
        onMutate: () => {
            setIsLoading(true);
        },
    });

    const filterData = allCollaterals?.data?.data?.find((item) => item?.head_id === selectedCollateral)?.data
    return (
        <div>
            <div className="page-body" onClick={open ? () => setOpen(!open) : undefined}>
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <div className="left-header">
                            <h4 className="fw-normal m-0">All Collaterals</h4>
                        </div>
                    </div>
                    <div className="row mt-lg-5 mt-md-4 mt-3">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body mt-lg-5 mt-md-4 mt-3">
                                    <div className="mb-4">
                                        <label className="fw-normal form-label" htmlFor="head">
                                            Head
                                        </label>
                                        {/* <col sm={12} md={12} ></col> */}
                                        <div className="left-tabs collateral-select">
                                            <div className="row align-items-center" >
                                                <div className="col-lg-3">
                                                    {/* <Dropdown>
                                                        <Dropdown.Toggle className="form-control form-select py-3 shadow-none px-4 text-start dropdown-to-select" id="dropdown-basic">
                                                            {selectedCollateral !== null ? headData?.find(item => item.head_id === selectedCollateral)?.collateral_name || "---Select Head---" : "---Select Head---"}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {allCollaterals?.data?.data?.map((item, index) => (
                                                                <Dropdown.Item key={item.head_id} onClick={() => handleSelectOption(item.head_id)}>
                                                                    <span className="select-text">{item.collateral_name}</span>
                                                                    <span className="dropdown-icons">
                                                                        <FontAwesomeIcon
                                                                            icon={faPencil}
                                                                            onClick={() => handelHeading(item.head_id)}
                                                                            className="edit-icon me-3"
                                                                        />
                                                                        <FontAwesomeIcon
                                                                            icon={faTrashAlt}
                                                                            onClick={() => handleRemoveTab(item.head_id)}
                                                                            className="delete-icon text-danger"
                                                                        />
                                                                    </span>
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown> */}
                                                    <DndProvider backend={HTML5Backend}>
                                                        <DropdownWithDragDrop
                                                            handleSelectOption={handleSelectOption}
                                                            allCollaterals={allCollaterals}
                                                            setSelectedCollateral={setSelectedCollateral}
                                                            handelHeading={handelHeading}
                                                            handleRemoveTab={handleRemoveTab}
                                                            selectedCollateral={selectedCollateral}
                                                            headData={headData}
                                                            handleReorderSubSections={handleReorderSubSections}
                                                            changeSortingIndexMutation={changeSortingIndexMutation}
                                                            open={open}
                                                            setOpen={setOpen}
                                                        />

                                                    </DndProvider>                                            </div>
                                                <div className="col-lg-3">
                                                    <Button variant="primary" className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2" onClick={handleShow}>
                                                        <FontAwesomeIcon icon={faPlus} /> Add
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                                {selectedCollateral && (
                                    <>
                                        <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">

                                            <h5 className="m-0 fw-medium">Manage Collaterals</h5>

                                            <div className="right-actions">
                                                <Button
                                                    onClick={() => handleViewMessageModal()}
                                                    className="btn btn-primary btn-theme fw-semibold text-uppercase ms-2 px-3 py-2"
                                                >
                                                    Add New
                                                </Button>
                                            </div>
                                        </div>
                                        {allCollaterals.isLoading || !allCollaterals?.data ? (
                                            <div className="text-center">Loading...</div>
                                        ) : filterData?.length === 0 ? (
                                            <div className="card-body text-center">No Data Available</div>
                                        ) : (
                                            <Table
                                                data={selectedCollateral !== null ? (allCollaterals?.data?.data?.find((item) => item?.head_id === selectedCollateral)?.data || []) : []}

                                                setData={setData}
                                                columns={columns}
                                                isShowingHeading={false}
                                                updateSortingTable={updateCollateralSorting}
                                                componentName={"collaterals"}
                                            />
                                        )}
                                    </>
                                )}


                                <DeleteCollateralsModal
                                    show={showDelModal}
                                    handleClose={handleCloseDelModal}
                                    rowData={selectedRowData}
                                    rowNameData={selectedRowNameData}
                                />



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showDelHeadModal ? <DeleteCollateralsHead
                show={showDelHeadModal}
                handleClose={handleCloseDelModal}
                rowData={selectedRowData}
            ></DeleteCollateralsHead> : ""}
            {/* add collateralheadmodal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formName">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            name="head"
                            type="text"
                            placeholder="Enter head name"
                            maxLength={100}
                            value={values1.head}
                            onChange={handleChange1}
                        />
                        {errors1.head && errors1.head ? (
                            <p className="form-error text-danger">{errors1.head}</p>
                        ) :
                            null}

                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary"
                        onClick={handleSubmit1}
                    >
                        {isLoading ? (
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            ></div>
                        ) : (
                            "SAVE"
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* edit modal  */}

            <Modal show={showEdit} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formName">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            name="head"
                            type="text"
                            placeholder="Enter head name"
                            maxLength={100}
                            value={values2.head}
                            onChange={handleChange2}
                        />
                        {errors2.head && errors2.head ? (
                            <p className="form-error text-danger">{errors2.head}</p>
                        ) :
                            null}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary"
                        onClick={handleSubmit2}
                    >
                        {isLoading ? (
                            <div
                                class="spinner-border text-primary"
                                role="status"
                            ></div>
                        ) : (
                            "SAVE"
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* add collaterals modal */}
            <Modal show={showMessageModal} onHide={closeMessageModal} centered>
                <Modal.Header closeButton className="px-5 py-4">
                    <Modal.Title as="h1" className="fs-5">
                        Collaterals
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-5">
                    <Form onSubmit={handleSubmit}>


                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="title">Title</Form.Label>
                            <textarea

                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="title"
                                className="form-control py-3 shadow-none px-4"
                                cols="30"
                                rows="3"
                                maxLength={70}
                            ></textarea>
                            {errors.title && touched.title ? (
                                <p className="form-error text-danger">{errors.title}</p>
                            ) :
                                null}
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="description">Description</Form.Label>
                            <textarea

                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="description"
                                className="form-control py-3 shadow-none px-4"
                                cols="30"
                                rows="3"
                                maxLength={70}
                            ></textarea>

                            {errors.description && touched.description ? (
                                <p className="form-error text-danger">{errors.description}</p>
                            ) :
                                null}
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="pdf">Upload Pdf</Form.Label>
                            {!collateralpdf && (
                                <div className="uploaded-property-img d-flex form-control p-0">
                                    <label
                                        htmlFor="UploadCover"
                                        className="upload-label position-relative w-100 rounded-3 px-4 py-5"
                                        onClick={() =>
                                            showCropMediaModal("", "secondImage")
                                        }
                                    >
                                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                            <Button
                                                type="button"
                                                className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                            >
                                                Choose pdf from gallery
                                            </Button>
                                        </div>
                                    </label>
                                </div>
                            )}
                            {collateralpdf && (

                                <div
                                    className="uploaded-property-img d-flex form-control p-0"
                                    style={{
                                        height: "125px",
                                        width: "141px",
                                        position: "relative",
                                    }}
                                >
                                    <div className="ml-3">
                                        <a href={collateralpdf} target="_blank">
                                            <img

                                                src={
                                                    collateralpdf?.type === "application/pdf"
                                                        ? pdfIcon
                                                        : collateralpdf
                                                }
                                                // style={{
                                                //   width: "100%",
                                                //   height: "100%",
                                                //   objectFit: "cover",
                                                // }}
                                                style={
                                                    collateralpdf?.type === "application/pdf"
                                                        ? {
                                                            width: "100px",
                                                            height: "130px",
                                                        }
                                                        : {
                                                            width: "100px",
                                                            height: "130px",
                                                            objectFit: "cover",
                                                        }
                                                }
                                            />
                                        </a>
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                            }}
                                        >
                                            <button
                                                className="remove-image-button"
                                                onClick={(e) => handleMainCroppedImage(e)}
                                            >
                                                <i className="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            )}

                        </Form.Group>
                        <div className="text-end">
                            <Button
                                variant="outline-primary"
                                className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                                onClick={closeMessageModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="outline-primary"
                                className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAction('PREVIEW');
                                    handleSubmit();
                                }}
                            >
                                {isPreviewLoading ? (
                                    <div
                                        className="spinner-border text-primary"
                                        role="status"
                                    ></div>
                                ) : (
                                    "PREVIEW"
                                )}
                            </Button>
                            <Button
                                variant="primary"
                                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAction('SAVE');
                                    handleSubmit();
                                }}
                            >
                                {isLoading ? (
                                    <div
                                        className="spinner-border text-primary"
                                        role="status"
                                    ></div>
                                ) : (
                                    "SAVE"
                                )}
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
            {showEditModal ? <EditCollaterals
                showEditModal={showEditModal} closeEditModal={closeEditModal} setShowEditModal={setShowEditModal} editColDetail={editColDetail} setEditColDetail={setEditColDetail} heading={heading} selectedCollateral={selectedCollateral} /> : ""}
            {/* image modal */}
            <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
                <Modal.Header className="modal-header px-5 py-4" closeButton>
                    <Modal.Title className="modal-title fs-5">
                        {"Select Image"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className=" overflow-x-auto overflow-y-auto">
                    {!folderId && <MediaFolderView setFolderId={setFolderId} />}
                    {folderId && !albumId && !websiteId && (
                        <ManageAlbumSelection
                            folderId={folderId}
                            setAlbumId={setAlbumId}
                            setwebsiteId={setwebsiteId}
                            setFolderId={setFolderId}
                        />
                    )}
                    {folderId && albumId && websiteId && (


                        <AlbumViewSelectionForProperties
                            albumId={albumId}
                            websiteId={websiteId}
                            setAlbumId={setAlbumId}
                            setwebsiteId={setwebsiteId}
                            dataCommingFrom="addCollaterals"
                            setFolderId={setFolderId}
                            setCropMediaModal1={setCropMediaModal}
                            // maincroppedImageForCol={collateralpdf}
                            // setmainCroppedImageForCol={setCollateralPdf}
                            checkMultipleImageSectionForCol={checkMultipleImageSection}
                            setmainCroppedFileForCol={setmainCroppedFile}
                            setSingleCropImageProp={setSingleCropImageProp}
                            setUnitPlanPdf={setUnitPlanPdf}
                            setting_value={settingValue}
                            mediaTypeCode={mediaType}
                            setMediaType={setMediaType}
                            setSingleFileProperties={setCollateralPdf}

                        />
                    )}
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default ManageCollaterals
