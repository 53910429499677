import React from "react";
import { getActivityLogs } from "../dataService/DataService";
import { useQuery } from "react-query";
import moment, { utc } from "moment";
import Table from "../../components/Table2";
import parse from "html-react-parser"

function Activitylogs() {
  const activityLogs = useQuery({
    queryKey: ["check"],
    queryFn: getActivityLogs,
  });
 
  const columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },
      // {
      //   Header: "Level",
      //   accessor: "level",
      //   Cell: ({ row }) => `${row.original.level}`,
      // },
      
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ row }) => `${row.original.type}`,
      },
      {
        Header: "Website",
        accessor: "website",
        Cell: ({ row }) => `${row.original.website}`,
      },
      {
        Header: "Module",
        accessor: "module",
        Cell: ({ row }) => `${row.original.module}`,
      },
      {
        Header: "Message",
        accessor: "message",
        Cell: ({ row }) => parse(row.original.message),
      },
      {
        Header: "User",
        accessor: "username",
        Cell: ({ row }) => `${row.original.username}`,
      },
      {
        Header: "Timestamp",
        accessor: "timestamp",
        Cell: ({ row }) => (
          <span className="" style={{ width: "200px" }}>
            {/* {moment(row.original.timestamp).format("MMM D, YYYY h:mm A [GMT]Z")} */}
            
            {moment.utc(parseInt(row?.original?.timestamp)).add(5, 'hours').add(30, 'minutes').format("YYYY-MM-DD HH:mm:ss A [GMT]+5:30")}
          </span>
        ),
      },
     
      
      
      
      
    ],
    []
  );

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <div className="left-header">
              <h4 className="fw-normal m-0">Activity Logs</h4>
            </div>
          </div>

          <div className="card mt-lg-5 mt-4">
            <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
              <h5 className="m-0 fw-medium">Activity LOGS</h5>
              {/* {newsletterData.data && (
                <CSVFileDownload
                  data={newsletterData?.data?.data?.data?.map((item, index) => [
                    index + 1,
                    item.email,
                    moment(item.last_requested_on).format(
                      "MMM D, YYYY h:mm A [GMT]Z"
                    ),
                  ])}
                  headers={headers}
                  filename="newsletter.xlsx"
                />
              )} */}
            </div>

            {activityLogs.data && (
              <Table
                data={activityLogs?.data?.data?.data}
                key={activityLogs?.data?.data?.data.getLogs}
                columns={columns}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Activitylogs;
