import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllPropertiesTypes } from "../../services/service";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { hasDuplicates } from "../../helper/CommonHelper";
import SpecificationsTab from "./SpecificationsTab";
import AmenitiesTab from "./AmenitiesTab";
import SignatureFeaturesTab from "./SignatureFeaturesTab";
import FootNotes from "./SignatureFeaturesTab";
import pdfIcon from "../../assets/images/pdf-doc.svg";
import videoImg from "../../assets/images/video.png";
import Button from "react-bootstrap/Button";
// crop image start
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelectionForProperties from "../manageAlbum/AlbumViewSelectionForProperties";
import * as querydata from "../dataService/DataService";
import AlbumViewSelectionDynamicAmenityIcon from "../manageAlbum/AlbumViewSelectionDynamicAmenityIcon";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragAbleImage from "./DragAbleImage";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { HiPlus } from "react-icons/hi";
import ImageTagModal from "../../components/ImageTagModal";
// crop image end
export const propertySchema = Yup.object({
  propertyName: Yup.string().trim().required("Please enter Property Name"),

 
  description: Yup.string().trim().required("please enter description"),
  tagline: Yup.string().trim().required("please enter restaurant tagline "),
  slugName: Yup.string().matches(/^[a-zA-Z0-9_-]*$/, 
  'Only alphanumeric characters, hyphens, and underscores are allowed')
  .required('Please enter slug'),
  metaTitle:Yup.string().trim(),

  keyWords: Yup.string().trim(),
  metaDescription: Yup.string().trim(),
  // area: Yup.string().trim().required("Please enter area"),
  area: Yup.string()
    .trim()
    .matches(/^\d+$/, "Area must be a number")
    .required("Please enter area"),
});
export default function CreateProperties() {
  const navigate = useNavigate();
  const ToolLink = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a className="text-dark">{children}</a>
    </OverlayTrigger>
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setErrors] = useState(false);

  const [image, setImage] = useState(null);

  // crop code start
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImageSorting, setmainCroppedImageSorting] = useState([]);
  const [maincroppedImage, setmainCroppedImage] = useState([]);
  const [maincroppedFile, setmainCroppedFile] = useState([]);

  const [singleFileProperties, setSingleFileProperties] = useState(null);
  const [singleCropImageProp, setSingleCropImageProp] = useState(null);
  const [checkValForSingleImg, setValForSingleImg] = useState("");
  const [checkMultipleImageSection, setMultipleImageSection] = useState("");
  const [isAmenityIcon, setIsAmenityIcon] = useState("");
  const [amenityFileData, setAmenityFile] = useState("");
  const [amenityCropImage, setAmenityCropImage] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [BannerImage, setBannerImage] = useState(null);
  const [maincroppedBannerImage, setMainCroppedBannerImage] = useState(null);
  const [mainCroppedBannerImageFile, setmainCroppedBannerImageFile] = useState(null);
  const [checkValForBannerImage, setValForBannerImage] = useState(null);
  const [settingValue, setSettingValue] = useState("")
  // const bannerImageRatio = useQuery(
  //   ["AspectRatio", "property_inner_banner_image_dimension"], // Using an array as the query key with offerId
  //   () => querydata.getAspectRatio("property_inner_banner_image_dimension")
  // );

  const getAspectRatio = useQuery(
    ["AspectRatio", "property_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("property_image_dimension")
  );

  const amenityIconDimension = useQuery(
    ["AspectRatio", "amenity_icon_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("amenity_icon_dimension")
  );
  const closeCropMediaModal = () => setCropMediaModal(false);
  const showCropMediaModal = (propValue) => {
    if (propValue === "singleUnitPlanImage") {
      setValForSingleImg(propValue);
      setMultipleImageSection("");
      setValForBannerImage("");
      setSettingValue(getAspectRatio?.data?.data?.setting_value ? getAspectRatio?.data?.data?.setting_value : 1 / 1);

    }
    // if (propValue === "BannerImage") {

    //   setValForBannerImage(propValue);
    //   setSettingValue(bannerImageRatio?.data?.data?.setting_value ? bannerImageRatio?.data?.data?.setting_value : 1 / 1);
    //   setMultipleImageSection("");
    //   setValForSingleImg("");
    //   setIsAmenityIcon(propValue);
    // }
    if (propValue === "multipleImageSectionBtn") {
      setMultipleImageSection(propValue);
      setValForSingleImg("");
      setValForBannerImage("");
      setSettingValue(getAspectRatio?.data?.data?.setting_value ? getAspectRatio?.data?.data?.setting_value : 1 / 1);
    }
    if (propValue === "amenityIcon") {
      setIsAmenityIcon(propValue);
      setValForBannerImage("");
      setSettingValue(amenityIconDimension?.data?.data?.setting_value ? amenityIconDimension?.data?.data?.setting_value : 1 / 1);

    }

    setCropMediaModal(true);
  };
  const handleBannerImage = (e) => {
    setmainCroppedBannerImageFile(null);
    if (e) {
      setBannerImage(null);
      setMainCroppedBannerImage(null);
    }

  }
  const [cropMediaModal, setCropMediaModal] = useState(false);

  const [innerBannerImgTag, setInnerBannerImgTag] = useState("");

  const [showModalImageTagModal, setImageTagModal] = useState(false);

  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [actions, setActions] = useState(null);
 
  // Function to handle closing the modal
  const handleCloseImageTagModal = () => {
    setImageTagModal(false);
  };

  // crop code end
  let allPropertiesTypes = useQuery(
    "getAllPropertiesTypes",
    getAllPropertiesTypes
  );
  async function imageUrlToFile(imageUrl) {
    try {
      // Fetch the image
      const response = await fetch(imageUrl);

      // Get the image data as a blob
      const blob = await response.blob();

      // Extract filename from URL
      const fileName = imageUrl.split("/").pop();

      // Create a new File object from the Blob
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      return null;
    }
  }
  const [duplicateIndexes, setDuplicateIndexes] = useState([]);

  let formDataError = false;
  const [unitPlanPdf, setUnitPlanPdf] = useState(null);

  const initialValues = {
    propertyName: "",
    slugName: "",
    metaTitle: "",
    keyWords: "",
    viewlink:"",
    metaDescription: "",
    description: "",
    tagline: "",
    features: [],
    footNotes: [],
    amenitiesId: [],
    area: "",
    poolSize: "",
    privatepool: "",
    propertyTypeId: "",
    // adultCount: "1",

    bedTypeId: [],
    bedCounts: [`1`],
    adultCount: [`1`],
    childrenCount: [`0`],
    adultCount1: "1",
    childrenCount1: "0",
    // innerBannerImgTag: "", // Set initial value for innerBannerImgTag
    propertyImgTag: "",  // Set initial value for propertyImgTag

  };


const propertyNameRef=useRef(null);
const descriptionRef=useRef(null);
const taglineRef=useRef(null);
const areaRef=useRef(null);
const maincroppedFileRef=useRef(null);
const propertyImagesRef=useRef(null);
const bedDataRef=useRef(null);
const slugRef=useRef(null);

  const handlePropertyNameChange = (event) => {
    const value = event.target.value;

    setFieldValue('propertyName', value);
    setFieldValue('slugName', value.toLowerCase().replace(/\s+/g, '-'));
  };
  const handleKeyPress = (event) => {
    const enteredChar = event.key;

    // Prevent typing characters other than lowercase letters (a-z) and hyphens (-)
    if (!/^[a-z0-9\-]$/i.test(enteredChar)) {
      event.preventDefault();
    }
  };


  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
    getFieldProps,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: propertySchema,
    onSubmit: async (values, action) => {

      const formData = new FormData();
      if (values.footNotes) {
        const footNoteArray = Object.values(values.footNotes);
        footNoteArray.map((value, index) => {
          formData.append(`footNotes[${index}]`, value);
        });
      }
      if (values.features) {
        const featuresArray = Object.values(values.features);
        featuresArray.map((value, index) => {
          formData.append(`signatureFeatures[${index}]`, value);
        });
      }
      if (values.amenitiesId) {
        values.amenitiesId.map((amtIds, index) => {
          formData.append(`amenities[${index}]`, amtIds);
        });
      }

      formData.append("propertyName", values.propertyName);
      formData.append("slugName", values.slugName);
      formData.append("metaTitle", values.metaTitle);
      formData.append("keyWords", values.keyWords);
      formData.append("metaDescription", values.metaDescription);
      formData.append("tagLine", values.tagline);
      formData.append("propertyTypeId", values.propertyTypeId);
      formData.append("viewlink", values.viewlink);

      // formData.append("bannerImgTag", values.innerBannerImgTag);
      formData.append("propertyImgTag", values.propertyImgTag);
      if (singleFileProperties) {
        formData.append("unitPlan", singleFileProperties);
      } else {
        formData.append("unitPlan", null);
      }

      const currentFileIds = maincroppedFile?.currentFileIds || [];




      if (currentFileIds !== "undefined" && currentFileIds.length > 0) {
        if (currentFileIds.length >= 2) {
          currentFileIds.map((croppedImage, index) => {
            formData.append(`propertyImages`, croppedImage);
            // formData.append(`imagesSortingIndex`, index);
          });
          
        } else {
          if(propertyImagesRef.current){
            propertyImagesRef.current.focus();
          }
          toast.dismiss();
          return toast.error("Minimum two property images are required.");
          
        }
      } else {
        if(propertyImagesRef.current){
          propertyImagesRef.current.focus();
        }
        toast.dismiss();
        return toast.error("Please upload property images ");
      }
      // if (mainCroppedBannerImageFile === null) {

      //   toast.dismiss();
      //   toast.error("Please upload inner banner image");
      //   if(maincroppedFileRef.current){
      //     maincroppedFileRef.current.focus()
      //   }
      //   return;
      // } else {
      //   formData.append("innerBannerImage", mainCroppedBannerImageFile);
      // }
      maincroppedImageSorting.map((croppedImage, index) => {
        formData.append("propertyImagesSorting", [croppedImage, index]);
      });
      formData.append("propertyDescription", values.description);
      formData.append("area", values.area);
      formData.append("privatePoolSize", values.poolSize);
      // formData.append("privatepool", values.privatepool);

      if (values.adultCount) {
        const occupancy = values.adultCount.map((adult, index) => ({
          adult: parseInt(adult),
          child: parseInt(values.childrenCount[index]),
        }));

        const duplicates = hasDuplicates(occupancy);
        setDuplicateIndexes(duplicates);
        if (duplicates.length > 0) {
          formDataError = true;
          toast.dismiss();
          toast.error(" Occupancy should be unique");
        } else {
          formDataError = false;
          formData.append("occupancy", JSON.stringify(occupancy));
        }
      }

      if (values.bedTypeId.length > 0 && values.bedTypeId.some(value => value !== "")) {

        if(!validateBed(values?.bedTypeId)){
          toast.dismiss()
          toast.error("bed is required ")
          return;
        }

        
        // const bedTypeData = values.bedTypeId.map((TypeId, index) => ({
        //   bedTypeId: parseInt(TypeId),
        //   bedCounts: parseInt(values.bedCounts[index]),
        // }));

        // formData.append("bedIds", JSON.stringify(bedTypeData));
        const validBedTypes = values.bedTypeId.filter(value => value !== null);

        // Map over the filtered array
        const bedTypeData = validBedTypes.map((TypeId, index) => ({
          bedTypeId: parseInt(TypeId),
          bedCounts: parseInt(values.bedCounts[index]),
        }));

        formData.append("bedIds", JSON.stringify(bedTypeData));
      } else {
        if(bedDataRef.current){
          bedDataRef.current.focus();
        }
        toast.dismiss();
        return toast.error("bed is required");
      }
  
    if (actions === "save") {
        if (!formDataError) {
          addMutation.mutate(formData);
        }
      }

      else if (actions === "preview") {
       
        preview.mutate(formData);
      }
   


    },

    validateOnBlur:false,
    validateOnChange:false

  }

  
);

const validateBed=(bed)=>{
 
  for(let i of bed){
    if(i===0 || i===null){
    
      return false
    }
  }
  return true
}

  useEffect(() => {
    if (Object.values(errors).some(error => error !== undefined)) {
      const firstEmptyField = Object.entries(errors).find(
        ([, error]) => error !== undefined && !touched[error.path]
      );
  
      if (firstEmptyField) {
        const [name] = firstEmptyField;
        
        switch (name) {
          case "propertyName":
            propertyNameRef.current.focus();
            break;
          case "description":
            descriptionRef.current.focus();
            break;
            case "tagline":
            taglineRef.current.focus();
            break;
          case "area":
            areaRef.current.focus();
            break;
            case "slugName":
              slugRef.current.focus();
              break;
                
                
          default:
            break;
        }
       
      }
    }
  }, [errors,touched]);
  


  const preview = useMutation(querydata.previewVilla, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {

      const websiteUrl = `${data.previewURL}?ispropertiespreview=true&uid=${data.encryptedUserId}`;
      window.open(websiteUrl, "_blank");

    },
    onSettled: () => {
      setIsPreviewLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsPreviewLoading(true); // Set loading to true before making the request
    },
  });


  //form post
  const addMutation = useMutation(querydata.insertPropertiesData, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-properties");
      setmainCroppedImage(null);
      setmainCroppedImageSorting(null);
      setImage(null);
      setmainCroppedImage(null);
      setBannerImage(null);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const handleRemoveUnitPlanImage = () => {
    setSingleFileProperties(null);
    setSingleCropImageProp(null);
    setUnitPlanPdf(null);
    setMediaType("");
  };
  const handleRemoveImage = (e, index) => {
    // Handle removing the image
    e.preventDefault();
    const updatedImages = [...maincroppedImage];
    const updatedImagesSorting = [...maincroppedImageSorting];
    if (maincroppedFile && maincroppedFile.currentFileIds) {
      const upadatePropFiles = [...maincroppedFile.currentFileIds];

      // Check if the index is valid

      if (index >= 0 && index < upadatePropFiles.length) {
        // Remove the image at the specified index

        upadatePropFiles.splice(index, 1);
        setmainCroppedFile(upadatePropFiles);
      } else {

      }
    }
    if (index >= 0 && index < updatedImages.length) {
      // Remove the image at the specified index
      updatedImages.splice(index, 1);
      setmainCroppedImage(updatedImages);
    } else {

    }
    if (index >= 0 && index < updatedImagesSorting.length) {
      // Remove the image at the specified index
      updatedImagesSorting.splice(index, 1);
      setmainCroppedImageSorting(updatedImagesSorting);
    } else {

    }

  };
  ///editor start
  const fullEditorRef = useRef(null);

  useEffect(() => {
    // Assuming fullEditorRef.current is the ReactQuill instance
    const quill = fullEditorRef.current?.getEditor();

    if (quill) {
      // Listen for text-change event in the Quill editor
      quill.on("text-change", () => {
        const descriptionValue = quill.root.innerHTML; // Get the HTML content of the editor
        setFieldValue("description", descriptionValue); // Update the Formik state
      });
    }
  }, [fullEditorRef.current]);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];

  ///editor end

  const moveImage = (from, to) => {
    const newImages = [...maincroppedImage];
    const newImagesSorting = [...maincroppedImageSorting];
    const AllFileImg = [...maincroppedFile?.currentFileIds];
    const allmovedImage = AllFileImg.splice(from, 1)[0];
    const movedImage = newImages.splice(from, 1)[0];
    const movedImageSorting = newImagesSorting.splice(from, 1)[0];
    newImages.splice(to, 0, movedImage);
    newImagesSorting.splice(to, 0, movedImageSorting);
    AllFileImg.splice(to, 0, allmovedImage);
    setmainCroppedImage(newImages);
    setmainCroppedImageSorting(newImagesSorting);
    setmainCroppedFile({ currentFileIds: AllFileImg });

  };

  const handleClosePropertyImgTagModal = () => {
    setShowPropertyImgModal(false);
};

// Function to close Inner Banner modal
const handleCloseInnerBannerModal = () => {
    setShowInnerBannerModal(false);
};
  const [showInnerBannerModal, setShowInnerBannerModal] = useState(false);
const handleToggleInnerBannerModal = () => {
    setShowInnerBannerModal(prevState => !prevState);
};

const [showPropertyImgModal, setShowPropertyImgModal] = useState(false);
const handleTogglePropertyImgModal = () => {
    setShowPropertyImgModal(prevState => !prevState);
};
  const handleSaveImageAltTag = (tagType, value) => {
    // Save the image alt tag value to state
   
    setFieldValue('propertyImgTag', value);
    setShowPropertyImgModal(false);

    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };

  // const handleSaveInnerBannerTag = (tagType, value) => {
  //   // Save the inner banner tag value to state
  
  //   setFieldValue('innerBannerImgTag', value);
  //   setShowInnerBannerModal(false)
  //   // You can also perform any additional actions here, such as making API calls to save the data to a server
  // };

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none"
                    to="/manage-properties"
                  >
                    Properties
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Manage Properties
                </li>
              </ol>
            </nav>
            <div className="left-header">
              <h4 className="fw-normal m-0">Manage Properties</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Create Your Property</h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="form-label" htmlFor="PropertyName">
                            Villa/Suite Name <span className="mandatory">*</span>
                          </label>
                          <div className="mb-4">
                            <input
                            ref={propertyNameRef}
                              type="text"
                              className="form-control py-3 shadow-none px-4"
                              name="propertyName"
                              id="PropertyName"
                              value={values.propertyName}
                              onChange={handlePropertyNameChange}
                              maxLength={200}
                            />
                          </div>
                          {errors.propertyName && touched.propertyName ? (
                            <p className="form-error text-danger">
                              {errors.propertyName}
                            </p>
                          ) : error.propertyName ? (
                            <p className="form-error text-danger">
                              {error.propertyName}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /col-md-6 --> */}
                        <div className="col-lg-6">
                          <label className="form-label" htmlFor="PropertyType">
                            Property type
                          </label>
                          <div className="mb-4">
                            <select
                              className="form-control form-select py-3 shadow-none px-4"
                              name="propertyTypeId"
                              id="PropertyType"
                              onChange={handleChange}
                              value={values.propertyTypeId}
                            >
                              <option value="">---Select---</option>
                              {allPropertiesTypes.data &&
                                allPropertiesTypes.data.data.map((option) => (
                                  <option
                                    key={option.property_type_id}
                                    value={option.property_type_id}
                                  >
                                    {option.property_type}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>


                       
                        <div className="col-md-12">
                          <label className="form-label" htmlFor="PropertyUnit">
                            Add Unit Plan
                          </label>
                          <div className="mb-4">
                           
                            {!singleCropImageProp && (
                              <label
                                htmlFor="UploadCover"
                                className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                onClick={() =>
                                  showCropMediaModal("singleUnitPlanImage")
                                }
                              >
                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                  >
                                    Choose from gallery
                                  </button>
                                </div>
                              </label>
                            )}
                            
                            {singleCropImageProp && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={singleCropImageProp} target="_blank">
                                    <img
                                      src={
                                        singleCropImageProp.endsWith(".pdf")
                                          ? pdfIcon
                                          : singleCropImageProp
                                      }
                                      // style={{
                                      //   width: "100%",
                                      //   height: "100%",
                                      //   objectFit: "cover",
                                      // }}
                                      style={
                                        singleCropImageProp.endsWith(".pdf")
                                          ? {
                                            width: "68%",
                                            height: "68%",
                                          }
                                          : {
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }
                                      }
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={() =>
                                        handleRemoveUnitPlanImage()
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* </>
                            )} */}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <label className="form-label" htmlFor="Description">
                          Property Description{" "}
                          <span className="mandatory">*</span>
                        </label>
                        <div className="mb-4">
                          <textarea
                          ref={descriptionRef}
                            name="description"
                            id="Description"
                            rows="3"
                            className="form-control py-3 shadow-none px-4"
                            value={values.description}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        {errors.description && touched.description ? (
                          <p className="form-error text-danger">
                            {errors.description}
                          </p>
                        ) : error.description ? (
                          <p className="form-error text-danger">
                            {error.description}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-12">
                        <label className="form-label" htmlFor="Description">
                          Tag Line{" "}
                          <span className="mandatory">*</span>
                        </label>
                        <div className="mb-4">
                          <textarea
                          ref={taglineRef}
                            name="tagline"
                            id="v"
                            rows="3"
                            className="form-control py-3 shadow-none px-4"
                            value={values.tagline}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        {errors.tagline && touched.tagline ? (
                          <p className="form-error text-danger">
                            {errors.tagline}
                          </p>
                        ) : error.tagline ? (
                          <p className="form-error text-danger">
                            {error.tagline}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-12 mb-4">
                        <label className="form-label mb-3" htmlFor="UploadProperty">
                          Upload property images{" "}
                          <span className="mandatory">*</span>
                          <span className="toolIcon ms-2">
                            <ToolLink
                              title="Minimum 2 images are required and these can be rearranged by dragging and dropping them in desired position."
                              id="t-1"
                            >
                              <BsFillInfoCircleFill />
                            </ToolLink>{" "}
                          </span>
                        </label>
                        <a title="Add image alt tag" className="btn image-tag" onClick={() => handleTogglePropertyImgModal("PROPERTYIMGTAG")}>
                          <HiPlus />
                        </a>
                        <DndProvider backend={HTML5Backend}>
                          <div className="uploaded-property-img d-flex flex-wrap">
                            {maincroppedImage.length === 0 && (
                              <label
                                htmlFor="UploadCover"
                                className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                onClick={() =>
                                  showCropMediaModal("multipleImageSectionBtn")
                                }
                                ref={propertyImagesRef}
                                tabIndex={-1}
                              >
                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                  >
                                    Choose from gallery
                                  </button>
                                </div>
                              </label>
                            )}
                            {maincroppedImage.length > 0 &&
                              maincroppedImage.map((imageURL, index) => (
                                <div
                                  key={index}
                                  className="property-img uploaded d-flex justify-content-center align-items-center position-relative"
                                // style={{
                                //   height: "200px",
                                //   width: "200px",
                                //   position: "relative",
                                // }}
                                >
                                  <div className="upload-img">
                                    <DragAbleImage
                                      maincroppedImage={imageURL}
                                      moveImage={moveImage}
                                      index={index}
                                      videoImg={videoImg}
                                    />
                                    {/* <a href={imageURL} target="_blank">
                                    <img
                                      // src={imageURL}
                                      src={
                                        imageURL.endsWith(".mp4")
                                          ? videoImg
                                          : imageURL
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a> */}
                                    {/* <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    > */}
                                    <button
                                      className="remove-image-button position-absolute end-0 top-0"
                                      onClick={(e) =>
                                        handleRemoveImage(e, index)
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                    {/* </div> */}
                                  </div>
                                </div>
                              ))}

                            {maincroppedImage.length > 0 && (
                              <div className="property-img d-flex justify-content-center align-items-center position-relative">
                                <div
                                  className="upload-img"
                                  onClick={() =>
                                    showCropMediaModal(
                                      "multipleImageSectionBtn"
                                    )
                                  }
                                >
                                  <i className="fa fa-plus"></i>
                                </div>
                              </div>
                            )}
                          </div>
                        </DndProvider>
                      </div>
                      <div className="col-md-12 mb-4">

                        <label
                          className="form-label"

                        >
                          360 VIEW LINK{" "}

                        </label>
                        <input
                          name="viewlink"
                          value={values.viewlink}
                          onChange={handleChange}
                          type="text"
                          id="Keywords"
                          className="form-control py-3 shadow-none px-4"

                        />


                      </div>

                      <div className="col-md-12 mb-4">


                        <label className="form-label" htmlFor="Name">Slug
                          <span className="mandatory">*</span></label>

                        <div className="bs-example">
                          <input onChange={handleChange}
                            type="text" maxLength={100} name="slugName" value={values?.slugName?.trim()} id="slugName" className="form-control py-3 shadow-none px-4" data-role="tagsinput"
                            onKeyPress={handleKeyPress} ref={slugRef} />
                        </div>
                        {errors.slugName && touched.slugName ? (
                          <p className="form-error text-danger">
                            {errors.slugName}
                          </p>
                        ) : error.slugName ? (
                          <p className="form-error text-danger">
                            {error.slugName}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-12 mb-4">

                        <label
                          className="form-label"

                        >
                          Keywords{" "}

                        </label>
                        <input
                          name="keyWords"
                          value={values.keyWords}
                          onChange={handleChange}
                          type="text"
                          id="Keywords"
                          className="form-control py-3 shadow-none px-4"

                        />


                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-4">

                            <label
                              className="form-label"
                            // htmlFor="OfferName"
                            >
                              Meta Title{" "}

                            </label>
                            <input
                              name="metaTitle"
                              value={values.metaTitle}
                              onChange={handleChange}
                              type="text"
                              id="metaTitle"
                              className="form-control py-3 shadow-none px-4"
                              maxLength={70}
                            />
                          </div>

                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label
                              className="form-label"
                            // htmlFor="OfferName"
                            >
                              Meta Description{" "}

                            </label>
                            <input
                              name="metaDescription"
                              value={values.metaDescription}
                              onChange={handleChange}
                              type="text"
                              id="metaDescription"
                              className="form-control py-3 shadow-none px-4"
                              maxLength={170}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <ul
                          className="nav nav-pills atmos-tabs d-flex flex-wrap pt-3 border-bottom pb-3"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase active"
                              id="specifications-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#specifications-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="specifications-tab-pane"
                              aria-selected="true"
                            >
                              Specifications
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase"
                              id="Amenities-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#Amenities-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="Amenities-tab-pane"
                              aria-selected="false"
                            >
                              Amenities
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase"
                              id="highlight-extra-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#highlight-extra-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="highlight-extra-tab-pane"
                              aria-selected="false"
                            >
                              Signature Features
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase"
                              id="FootNotes-extra-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#FootNotes-extra-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="FootNotes-extra-tab-pane"
                              aria-selected="false"
                            >
                             FootNotes
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content mt-4 mb-0" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="specifications-tab-pane"
                          role="tabpanel"
                          aria-labelledby="specifications-tab"
                          tabIndex="0"
                        >
                          <SpecificationsTab
                          bedDataRef={bedDataRef}
                            errors={errors}
                            error={error}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            setValues={setValues}
                            initialValues={initialValues}
                            duplicateIndexes={duplicateIndexes}
                            setDuplicateIndexes={setDuplicateIndexes}
                            areaRef={areaRef}
                          />
                        </div>

                        <div
                          className="tab-pane fade"
                          id="Amenities-tab-pane"
                          role="tabpanel"
                          aria-labelledby="Amenities-tab"
                          tabIndex="0"
                        >
                          <AmenitiesTab
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            error={error}
                            errors={errors}
                            touched={touched}
                            showCropMediaModal={showCropMediaModal}
                            amenityFileData={amenityFileData}
                            setAmenityCropImage={setAmenityCropImage}
                            amenityCropImage={amenityCropImage}
                            setAmenityFile={setAmenityFile}
                          />
                        </div>

                        <div
                          className="tab-pane fade"
                          id="highlight-extra-tab-pane"
                          role="tabpanel"
                          aria-labelledby="highlight-extra-tab"
                          tabIndex="0"
                        >
                          <SignatureFeaturesTab
                            heading={'Signature Features'}
                            labelHeading={'Add Feature'}
                           placeholder={'Enter Feature'}

                            field={getFieldProps("features")}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            setValues={setValues}
                            signatureFeaturesData={initialValues.features}
                          />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="FootNotes-extra-tab-pane"
                          role="tabpanel"
                          aria-labelledby="FootNotes-extra-tab"
                          tabIndex="0"
                        >
                          <FootNotes
                          heading={'FootNotes'}
                          labelHeading={'Add FootNote'}
                          placeholder={'Enter FootNote'}
                            field={getFieldProps("footNotes")}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            setValues={setValues}
                            signatureFeaturesData={initialValues.footNotes}
                          />
                        </div>
                      </div>

                      <div className="col-12 text-end mb-2 mt-lg-5 mt-4">
                      <a
                          // href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setActions('preview');
                            handleSubmit();
                          }}

                          className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                        >
                          {isPreviewLoading ? (
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            ></div>
                          ) : (
                            "PREVIEW"
                          )}
                        </a>
                        <Button
                          type="submit"
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                          onClick={(e) => {
                            e.preventDefault();
                            setActions('save');
                            handleSubmit();
                          }}
                        >
                          {isLoading ? <div className="spinner-border text-primary" role="status"></div> : 'SAVE'}
                        </Button>
                       
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Media"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {isAmenityIcon === "amenityIcon" ? (
            <>
              {folderId && albumId && websiteId && (
                <AlbumViewSelectionDynamicAmenityIcon
                  albumId={albumId}
                  websiteId={websiteId}
                  setAlbumId={setAlbumId}
                  setwebsiteId={setwebsiteId}
                  setFolderId={setFolderId}
                  setCropMediaModal1={setCropMediaModal}
                  setAmenityFile={setAmenityFile}
                  setAmenityCropImage={setAmenityCropImage}
                  setting_value={settingValue}
                />
              )}
            </>
          ) : (
            <>
              {folderId && albumId && websiteId && (
                <AlbumViewSelectionForProperties
                  albumId={albumId}
                  websiteId={websiteId}
                  setAlbumId={setAlbumId}
                  setwebsiteId={setwebsiteId}
                  setCropMediaModal1={setCropMediaModal}
                  setmainCroppedImage={setmainCroppedImage}
                  setmainCroppedImageSorting={setmainCroppedImageSorting}
                  setmainCroppedFile={setmainCroppedFile}
                  setSingleFileProperties={setSingleFileProperties}
                  setSingleCropImageProp={setSingleCropImageProp}
                  setMainCroppedBannerImage={setMainCroppedBannerImage}
                  checkValForBannerImage={checkValForBannerImage}
                  setmainCroppedBannerImageFile={setmainCroppedBannerImageFile}
                  setUnitPlanPdf={setUnitPlanPdf}
                  checkValForSingleImg={checkValForSingleImg}
                  checkMultipleImageSection={checkMultipleImageSection}
                  mediaTypeCode={mediaType}
                  setMediaType={setMediaType}
                  // setting_value={
                  //   getAspectRatio &&
                  //   getAspectRatio.data &&
                  //   getAspectRatio.data.data &&
                  //   getAspectRatio.data.data.setting_value
                  // }
                  setting_value={settingValue}
                />
              )}
            </>
          )}
        </Modal.Body>
      </Modal>

      {/* <ImageTagModal
        showModalImageTagModal={showModalImageTagModal}
        handleCloseImageTagModal={handleCloseImageTagModal}
        tagType={tagType}
        handleSaveImageAltTag={handleSaveImageAltTag}
        initialImageAltTag={
        tagType === "INNERBANNER"
            ? initialValues.innerBannerImgTag // Set initial value based on tagType
            : tagType === "PROPERTYIMGTAG"
            ? initialValues.propertyImgTag // Set initial value based on tagType
            : "" // Default value if tagType is neither "INNERBANNER" nor "PROPERTYIMGTAG"
    }
      /> */}
      <ImageTagModal
        showModalImageTagModal={showPropertyImgModal}
        handleCloseImageTagModal={handleClosePropertyImgTagModal}
        initialImageAltTag={values.propertyImgTag}
        tagType="PROPERTYIMGTAG"
        handleSaveImageAltTag={handleSaveImageAltTag}
        inputName="propertyImgTag"
      />

      {/* <ImageTagModal
        showModalImageTagModal={showInnerBannerModal}
        handleCloseImageTagModal={handleCloseInnerBannerModal}
        initialImageAltTag={values.innerBannerImgTag}
        tagType="INNERBANNER"
        handleSaveImageAltTag={handleSaveInnerBannerTag}
        inputName="innerBannerTag"
      /> */}
    </>
  );
}
