import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllPropertiesTypes } from "../../services/service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { hasDuplicates } from "../../helper/CommonHelper";
import AmenitiesTab1 from "./AmenitiesTab1";
import SignatureFeaturesEditTab from "./SignatureFeaturesEditTab";
import pdfIcon from "../../assets/images/pdf-doc.svg";
import videoImg from "../../assets/images/video.png";
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelectionForProperties from "../manageAlbum/AlbumViewSelectionForProperties";
import * as querydata from "../dataService/DataService";
import { updateProperty } from "../dataService/DataService";
import AlbumViewSelectionDynamicAmenityIcon from "../manageAlbum/AlbumViewSelectionDynamicAmenityIcon";
import SpecificationsTabEdit from "../manageProperties/SpecificationTabEdit";
import backarrow from "../../assets/images/back-arrow.svg";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { BsFillInfoCircleFill } from "react-icons/bs";
// import DraggableImage from "./DraggableImage";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragAbleImage from "./DragAbleImage";
import Button from "react-bootstrap/Button";

import { HiPlus } from "react-icons/hi";
import ImageTagModal from "../../components/ImageTagModal";

export const propertySchema = Yup.object({
  propertyName: Yup.string().trim().required("Please enter Property Name"),

  slugName: Yup.string()
    .matches(
      /^[a-zA-Z0-9_-]*$/,
      "Only alphanumeric characters, hyphens, and underscores are allowed"
    )
    .required("Please enter slug"),
  metaTitle: Yup.string().trim(),
  keyWords: Yup.string().trim(),
  metaDescription: Yup.string().trim(),

  tagline: Yup.string().trim().required("Please enter tagline"),
  // bedTypeId: Yup.array().min(1, "Please select at least one bed").required(),
  description: Yup.string().trim().required("please enter description"),

  metaTitle: Yup.string().trim(),

  keyWords: Yup.string().trim(),
  metaDescription: Yup.string().trim(),
  // area: Yup.string().required("Please enter area"),
  area: Yup.string()
    .trim()
    .matches(/^\d+$/, "Area must be a number")
    .required("Please enter area"),
});
export default function EditProperty() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setErrors] = useState({});
  const [offerfile, setofferfile] = useState("");
  const [image, setImage] = useState(null);
  const [unitPlanPdf, setUnitPlanPdf] = useState(null);
  // crop code start
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImageSorting, setmainCroppedImageSorting] = useState([]);
  const [maincroppedImage, setmainCroppedImage] = useState([]);
  const [maincroppedFile, setmainCroppedFile] = useState([]);

  const [singleFileProperties, setSingleFileProperties] = useState(null);
  const [singleCropImageProp, setSingleCropImageProp] = useState(null);
  const [checkValForSingleImg, setValForSingleImg] = useState("");
  const [checkMultipleImageSection, setMultipleImageSection] = useState("");
  const [isAmenityIcon, setIsAmenityIcon] = useState("");
  const [amenityFileData, setAmenityFile] = useState("");
  const [amenityCropImage, setAmenityCropImage] = useState("");
  const [AllPropertiesImages, setAllPropertiesImages] = useState([]);
  const [BannerImage, setBannerImage] = useState(null);
  const [actions, setActions] = useState(null);
  const [maincroppedBannerImage, setMainCroppedBannerImage] = useState(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [mainCroppedBannerImageFile, setmainCroppedBannerImageFile] =
    useState(null);

  const [checkValForBannerImage, setValForBannerImage] = useState(null);
  const [deletedMultiImageArray, setMultiDeletedImgArray] =
    useState(AllPropertiesImages);
  const [mediaType, setMediaType] = useState("");
  const [cropMediaModal, setCropMediaModal] = useState(false);
  const ToolLink = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a className="text-dark">{children}</a>
    </OverlayTrigger>
  );

  const [settingValue, setSettingValue] = useState("");

  const bannerImageRatio = useQuery(
    ["AspectRatio", "property_inner_banner_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("property_inner_banner_image_dimension")
  );

  const getAspectRatio = useQuery(
    ["AspectRatio", "property_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("property_image_dimension")
  );

  const amenityIconDimension = useQuery(
    ["AspectRatio", "amenity_icon_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("amenity_icon_dimension")
  );

  const showCropMediaModal = (propValue) => {
    if (propValue === "singleUnitPlanImage") {
      setValForSingleImg(propValue);
      setMultipleImageSection("");
      setValForBannerImage("");

      setSettingValue(
        getAspectRatio?.data?.data?.setting_value
          ? getAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    // if (propValue === "BannerImage") {
    //   setSettingValue(bannerImageRatio?.data?.data?.setting_value ? bannerImageRatio?.data?.data?.setting_value : 1 / 1);

    //   setValForBannerImage(propValue);
    //   setMultipleImageSection("");
    //   setValForSingleImg(null);
    //   setIsAmenityIcon("");
    // }
    if (propValue === "multipleImageSectionBtn") {
      setMultipleImageSection(propValue);
      setValForSingleImg("");
      setValForBannerImage("");

      setSettingValue(
        getAspectRatio?.data?.data?.setting_value
          ? getAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    if (propValue === "amenityIcon") {
      setIsAmenityIcon(propValue);
      setValForBannerImage("");

      setSettingValue(
        amenityIconDimension?.data?.data?.setting_value
          ? amenityIconDimension?.data?.data?.setting_value
          : 1 / 1
      );
    }

    setCropMediaModal(true);
  };

  const closeCropMediaModal = () => setCropMediaModal(false);
  // const handleBannerImage = (e, image) => {
  //   image = undefined;
  //   // Handle removing the image
  //   if (e) {
  //     setBannerImage(undefined);
  //     setMainCroppedBannerImage(undefined);
  //   }
  // };

  const [showModalImageTagModal, setImageTagModal] = useState(false);
  const handleShowImageTagModal = () => {
    setImageTagModal(true);
  };

  const handleCloseImageTagModal = () => {
    setImageTagModal(false);
  };

  // crop code end

  let allPropertiesTypes = useQuery(
    "getAllPropertiesTypes",
    getAllPropertiesTypes
  );

  const [poolSizeValue, setPoolSizeValue] = useState({});
  const { id } = useParams();
  const getSingleProperty = useQuery(["getSingleProperty", id], () =>
    querydata.getSingleProperty(id)
  );

  const [duplicateIndexes, setDuplicateIndexes] = useState([]);
  let formDataError = false;

  const uniqueFileIds = new Set();

  useEffect(() => {
    setFieldValue("amenitiesId", initialValues.amenitiesId);

    setValues({ ...initialValues });

    const originalUrl =
      getSingleProperty?.data?.data?.[0]?.property_data?.[0]?.unit_plan_path;
    const modifiedUrl =
      originalUrl &&
      originalUrl.replace("http://172.16.1.127:7000", "http://localhost:7000");

    setImage(modifiedUrl);
    setSingleCropImageProp(modifiedUrl);
    // const bannerImageUrl =
    //   getSingleProperty?.data?.data?.[0]?.property_data?.[0]
    //     ?.inner_banner_image;
    // setMainCroppedBannerImage(bannerImageUrl);
    const imagesData = getSingleProperty?.data?.data?.[3]?.images_data;

    setAllPropertiesImages(imagesData);

    if (Array.isArray(imagesData) && imagesData.length > 0) {
      const modifiedImageUrls = imagesData.map((image) =>
        image.image_path.replace(
          "http://172.16.1.127:7000",
          "http://localhost:7000"
        )
      );

      setmainCroppedImage(modifiedImageUrls);
      setmainCroppedImageSorting(modifiedImageUrls);
      setmainCroppedFile((prevImages) => {
        const imagesArray = Array.isArray(prevImages) ? prevImages : [];
        return [...imagesArray, ...modifiedImageUrls];
      });
    }
  }, [getSingleProperty?.data]);

  const initialValues = {
    tagline: getSingleProperty?.data?.data[0]?.property_data[0]?.tag_line,
    slugName: getSingleProperty?.data?.data[0]?.property_data[0]?.slug,
    viewlink: getSingleProperty?.data?.data[0]?.property_data[0]?.view_360_link,
    metaTitle:
      getSingleProperty?.data?.data[0]?.property_data[0]?.meta_title || "",
    keyWords:
      getSingleProperty?.data?.data[0]?.property_data[0]?.keywords || "",
    metaDescription:
      getSingleProperty?.data?.data[0]?.property_data[0]?.meta_description ||
      "",
    // innerBannerImgTag: getSingleProperty?.data?.data[0]?.property_data[0]?.inner_banner_alt_tag||"",
    propertyImgTag: (() => {
      let proAltTag = [];
      const propertyAltTag = getSingleProperty?.data?.data?.find(
        (img) => img.images_data && img.images_data.length > 0
      );

      if (propertyAltTag) {
        proAltTag = propertyAltTag.images_data.map(
          (item) => item.property_image_alt_tag
        );
      }

      return proAltTag.length > 0 ? proAltTag[0] : "";
    })(),
    // propertyImgTag: getSingleProperty?.data?.data[0]?.images_data[0]?.property_image_alt_tag||"",
    // propertyImgTag: getSingleProperty?.data?.data[0]?.images_data && getSingleProperty.data.data[0].images_data.length > 0 ? getSingleProperty.data.data[0].images_data[0].property_image_alt_tag : "",
    //  propertyImgTag : getSingleProperty?.data?.data[0].find(item => item.images_data)?.images_data[0]?.property_image_alt_tag || "",

    propertyName: (() => {
      let initialValuePropertyName = "";

      if (
        getSingleProperty?.data?.data &&
        Array.isArray(getSingleProperty.data.data) &&
        getSingleProperty.data.data.length > 0
      ) {
        const resultData = getSingleProperty.data.data[0];
        if (
          resultData &&
          resultData.property_data &&
          Array.isArray(resultData.property_data) &&
          resultData.property_data.length > 0
        ) {
          const propertyData = resultData.property_data[0];
          if (propertyData && propertyData.property_name) {
            initialValuePropertyName = propertyData.property_name;
          }
        }
      }

      return initialValuePropertyName;
    })(),

    description: (() => {
      let initialPropertyDescription = "";
      if (
        getSingleProperty?.data?.data &&
        Array.isArray(getSingleProperty.data.data) &&
        getSingleProperty.data.data.length > 0
      ) {
        const resultData = getSingleProperty.data.data[0];
        if (
          resultData &&
          resultData.property_data &&
          Array.isArray(resultData.property_data) &&
          resultData.property_data.length > 0
        ) {
          const descriptionData = resultData.property_data[0];
          if (descriptionData && descriptionData.property_description) {
            initialPropertyDescription = descriptionData.property_description;
          }
        }
      }
      return initialPropertyDescription;
    })(),
    propertyTypeId: (() => {
      let initialPropertyId = "";
      if (
        getSingleProperty?.data?.data &&
        Array.isArray(getSingleProperty.data.data) &&
        getSingleProperty.data.data.length > 0
      ) {
        const resultData = getSingleProperty.data.data[0];
        if (
          resultData &&
          resultData.property_data &&
          Array.isArray(resultData.property_data) &&
          resultData.property_data.length > 0
        ) {
          const propertyType = resultData.property_data[0];
          if (propertyType && propertyType.property_type_id) {
            initialPropertyId = propertyType.property_type_id;
          }
        }
      }
      return initialPropertyId;
    })(),
    poolSize: (() => {
      let initialPoolSize = "";
      if (
        getSingleProperty?.data?.data &&
        Array.isArray(getSingleProperty.data.data) &&
        getSingleProperty.data.data.length > 0
      ) {
        const resultData = getSingleProperty.data.data[0];
        if (
          resultData &&
          resultData.property_data &&
          Array.isArray(resultData.property_data) &&
          resultData.property_data.length > 0
        ) {
          const poolSize = resultData.property_data[0];
          if (poolSize && poolSize.private_pool_size) {
            initialPoolSize = poolSize.private_pool_size;
          }
        }
      }
      return initialPoolSize;
    })(),
    adultCount: (() => {
      let adultarray = [];
      const propertyOccupancy = getSingleProperty?.data?.data?.find(
        (occu) => occu.property_occupancy?.length > 0
      );
      return (adultarray = propertyOccupancy?.property_occupancy?.map(
        (adltCount) => adltCount.adult_count
      ));
    })(),
    // adultCount1: (() => {
    //   let initialOccupancy2Adult = "";
    //   if (
    //     getSingleProperty?.data?.data &&
    //     Array.isArray(getSingleProperty.data.data) &&
    //     getSingleProperty.data.data.length > 0
    //   ) {
    //     const resultData = getSingleProperty.data.data[0];
    //     if (
    //       resultData &&
    //       resultData.property_data &&
    //       Array.isArray(resultData.property_data) &&
    //       resultData.property_data.length > 0
    //     ) {
    //       const adultCount1 = resultData.property_data[0];
    //       if (adultCount1 && adultCount1.occupancy2_adult) {
    //         initialOccupancy2Adult = adultCount1.occupancy2_adult;
    //       }
    //     }
    //   }
    //   return initialOccupancy2Adult;
    // })(),
    childrenCount: (() => {
      let childarray = [];
      const propertyOccupancy = getSingleProperty?.data?.data?.find(
        (occu) => occu.property_occupancy?.length > 0
      );
      return (childarray = propertyOccupancy?.property_occupancy?.map(
        (childCount) => childCount.child_count
      ));
    })(),
    // childrenCount1: (() => {
    //   let initialOccupancy2Child = "";
    //   if (
    //     getSingleProperty?.data?.data &&
    //     Array.isArray(getSingleProperty.data.data) &&
    //     getSingleProperty.data.data.length > 0
    //   ) {
    //     const resultData = getSingleProperty.data.data[0];
    //     if (
    //       resultData &&
    //       resultData.property_data &&
    //       Array.isArray(resultData.property_data) &&
    //       resultData.property_data.length > 0
    //     ) {
    //       const childrenCount1 = resultData.property_data[0];
    //       if (childrenCount1 && childrenCount1.occupancy2_child) {
    //         initialOccupancy2Child = childrenCount1.occupancy2_child;
    //       }
    //     }
    //   }
    //   return initialOccupancy2Child;
    // })(),
    area: (() => {
      let initialArea = "";
      if (
        getSingleProperty?.data?.data &&
        Array.isArray(getSingleProperty.data.data) &&
        getSingleProperty.data.data.length > 0
      ) {
        const resultData = getSingleProperty.data.data[0];
        if (
          resultData &&
          resultData.property_data &&
          Array.isArray(resultData.property_data) &&
          resultData.property_data.length > 0
        ) {
          const area = resultData.property_data[0];
          if (area && area.area_sqm) {
            initialArea = area.area_sqm;
          }
        }
      }
      return initialArea;
    })(),
   
    footNotes : (() => {
      let initialFootnotes = [];
      if (
        getSingleProperty?.data?.data &&
        Array.isArray(getSingleProperty.data.data) &&
        getSingleProperty?.data?.data?.length > 0
      ) {
        const resultData = getSingleProperty.data.data[5];
    
      
        /////////
        if (
          resultData &&
          resultData.footnotes_data &&
          Array.isArray(resultData.footnotes_data) &&
          resultData.footnotes_data.length > 0
        ) {
          const finalData = resultData.footnotes_data.map(
            (d) => d.footnotes
          );

          initialFootnotes = finalData;
        }
      }
    
      return initialFootnotes;
    })(),
      
    features: (() => {
      let initialFeature = "";
      if (
        getSingleProperty?.data?.data &&
        Array.isArray(getSingleProperty.data.data) &&
        getSingleProperty.data.data.length > 0
      ) {
        const resultData = getSingleProperty.data.data[4];

        if (
          resultData &&
          resultData.sign_features_data &&
          Array.isArray(resultData.sign_features_data) &&
          resultData.sign_features_data.length > 0
        ) {
          const finalData = resultData.sign_features_data.map(
            (d) => d.sign_feature
          );

          initialFeature = finalData;
        }
      }

      return initialFeature;
    })(),

    privatepool: "",
    bedCounts: (() => {
      let initialBedCounts = "";
      const resultData = getSingleProperty?.data?.data.find(
        (occu) => occu.bed_data?.length > 0
      );
      const finalCountData = resultData?.bed_data.map(
        (bedType) => bedType.bed_count
      );
      initialBedCounts = finalCountData;
      return initialBedCounts;
    })(),
    bedTypeId: (() => {
      // let initialBedType = "";
      // if (
      //   getSingleProperty?.data?.data &&
      //   Array.isArray(getSingleProperty?.data?.data) &&
      //   getSingleProperty?.data?.data.length > 0
      // ) {
      //   const resultData = getSingleProperty.data.data[1]; // Assuming you want the first item in the array

      //   if (
      //     resultData &&
      //     resultData.bed_data &&
      //     Array.isArray(resultData?.bed_data) &&
      //     resultData?.bed_data?.length > 0
      //   ) {
      //     const finalData = resultData?.bed_data.map(
      //       (bedType) => bedType.bed_type_id
      //     );
      //     initialBedType = finalData;
      //   }
      // }

      // return initialBedType;

      let initialBedType = "";
      const resultData = getSingleProperty?.data?.data?.find(
        (bed) => bed.bed_data?.length > 0
      );
      const finalData = resultData?.bed_data.map(
        (bedType) => bedType.bed_type_id
      );
      initialBedType = finalData;
      return initialBedType;
    })(),

    amenitiesId: (() => {
      const propertyData = getSingleProperty?.data?.data?.find(
        (property) => property.amenity_data?.length > 0
      );
      return propertyData?.amenity_data?.map((amdata) => amdata.amenity_id);
      // return [...propertyData?.amenity_data];
    })(),
    deletedMultiImageArray: (() => {
      if (deletedMultiImageArray && deletedMultiImageArray.length > 0) {
        return deletedMultiImageArray.map((DltImgData, index) => {
          // perform operations on DltImgData if needed
          return DltImgData;
        });
      } else if (AllPropertiesImages && AllPropertiesImages.length > 0) {
        // If deletedMultiImageArray is empty, return AllPropertiesImages
        return AllPropertiesImages.map((imgData, index) => {
          // perform operations on imgData if needed
          return imgData;
        });
      } else {
        // Handle the case where both deletedMultiImageArray and AllPropertiesImages are empty or undefined
        return [];
      }
    })(),
  };
 
  async function imageUrlToFile(imageUrl) {
    try {
      // Fetch the image
      const response = await fetch(imageUrl);

      // Get the image data as a blob
      const blob = await response.blob();

      // Extract filename from URL
      const fileName = imageUrl.split("/").pop();

      // Create a new File object from the Blob
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      return null;
    }
  }

  const propertyNameRef = useRef(null);
  const descriptionRef = useRef(null);
  const taglineRef = useRef(null);
  const areaRef = useRef(null);
  const maincroppedFileRef = useRef(null);
  const propertyImagesRef = useRef(null);
  const bedDataRef = useRef(null);
  const slugRef = useRef(null);

  const handlePropertyNameChange = (event) => {
    const value = event.target.value;

    setFieldValue("propertyName", value);
    // setFieldValue("slugName", value.toLowerCase().replace(/\s+/g, "-"));
  };
  const handleKeyPress = (event) => {
    const enteredChar = event.key;

    // Prevent typing characters other than lowercase letters (a-z) and hyphens (-)
    if (!/^[a-z0-9\-]$/i.test(enteredChar)) {
      event.preventDefault();
    }
  };

  const validateBed=(bed)=>{
 
    for(let i of bed){
      if(i===0 || i===null){
      
        return false
      }
    }
    return true
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: propertySchema,
    onSubmit: async (values, action) => {
      const formData = new FormData();
      // if (values.bedTypeId) {
      //   const bedtypejsonString = JSON.stringify(values.bedTypeId);
      //   const jsonObjectBedType = JSON.parse(bedtypejsonString);
      //   const resulBedTypeIdtArray =
      //     Object.values(jsonObjectBedType).map(Number);
      //   const bids = `[${resulBedTypeIdtArray}]`;
      //   const bArrayids = JSON.parse(bids);

      //   bArrayids.forEach((id, index) => {
      //     formData.append(`bedIds[${index}]`, id);
      //   });
      // }
      if (values.bedTypeId.length > 0) {

        if(!validateBed(values?.bedTypeId)){
          toast.dismiss()
          toast.error("bed is required ")
          return;
        }
        
        const bedTypeData = values.bedTypeId.map((TypeId, index) => ({
          bedTypeId: parseInt(TypeId),
          bedCounts: parseInt(values.bedCounts[index]),
        }));

        formData.append("bedIds", JSON.stringify(bedTypeData));
      } else {
        if (bedDataRef.current) {
          bedDataRef.current.focus();
        }
        toast.dismiss();
        return toast.error("bed required");
      }
      const footNotesArray = Object.values(values.footNotes);
      if (footNotesArray) {
        footNotesArray.map((value, index) => {
          formData.append(`footNotes[${index}]`, value);
        });
      }
      const featuresArray = Object.values(values.features);
      if (featuresArray) {
        featuresArray.map((value, index) => {
          formData.append(`signatureFeatures[${index}]`, value);
        });
      }
      if (values.amenitiesId) {
        values.amenitiesId.forEach((amtId, index) => {
          formData.append(`amenities[${index}]`, amtId);
        });
      }

      formData.append("propertyName", values.propertyName);
      formData.append("viewlink", values.viewlink);
      formData.append("slugName", values.slugName);
      formData.append("metaTitle", values.metaTitle);
      formData.append("keyWords", values.keyWords);
      formData.append("metaDescription", values.metaDescription);
      formData.append("propertyDescription", values.description);
      formData.append("area", values.area);
      formData.append("privatePoolSize", values.poolSize);
      formData.append("tagLine", values.tagline);
      formData.append("propertyImgTag", values.propertyImgTag);
      // formData.append("adult1", values.adultCount);
      // formData.append("child1", values.childrenCount);
      // formData.append("adult2", values.adultCount1);
      // formData.append("child2", values.childrenCount1);
      if (values.adultCount) {
        const occupancy = values?.adultCount?.map((adult, index) => ({
          adult: parseInt(adult),
          child: parseInt(values.childrenCount[index]),
        }));
        const duplicates = hasDuplicates(occupancy);
        setDuplicateIndexes(duplicates);
        if (duplicates.length > 0) {
          formDataError = true;
          toast.dismiss();
          toast.error("Occupancy should be unique.");
        } else {
          formDataError = false;
          formData.append("occupancy", JSON.stringify(occupancy));
        }
      }

      formData.append("propertyTypeId", values.propertyTypeId);

      const currentFileIds = maincroppedFile?.currentFileIds || [];

      if (currentFileIds != "") {
        currentFileIds?.forEach((croppedImage, index) => {
          if (!uniqueFileIds.has(croppedImage)) {
            formData.append(`propertyImages`, croppedImage);
             uniqueFileIds.add(croppedImage);
          }
        });
      }

      maincroppedImageSorting.map((croppedImage, index) => {
        formData.append("propertyImagesSorting", [croppedImage, index]);
      });
      // maincroppedImageSorting.map((croppedImage, index) => {
      //   formData.append("propertyImagesSorting", [croppedImage,index]);
      // });

      AllPropertiesImages.map((croppedImage, index) => {
        if (croppedImage.property_imgae_id)
          formData.append("propertyImagesSortingPrev", [
            croppedImage.property_imgae_id,
            index,
          ]);
      });
      // else {
      //   toast.dismiss();
      //   return toast.error("property images required");
      // }
      // maincroppedImage.map((croppedImage, index) => {
      //   formData.append("propertyImagesSorting", [croppedImage,index]);
      // });
      if (!deletedMultiImageArray || deletedMultiImageArray.length === 0) {
        // Append images from AllPropertiesImages
        if (AllPropertiesImages.length === 0) {
          formData.append(`presentOldPropertyImages`, null);
        } else {
          AllPropertiesImages?.map((oldImgData, index) => {
            formData.append(`presentOldPropertyImages`, oldImgData?.image_path);
            //  formData.append(`imagesSortingIndex`, index);
          });
        }
      } else {
        // Append images from deletedMultiImageArray

        deletedMultiImageArray?.map((DltImgData, index) => {
          formData.append(`presentOldPropertyImages`, DltImgData);
        });
      }

      if (
        currentFileIds.length === 0 &&
        deletedMultiImageArray.length === 0 &&
        AllPropertiesImages.length === 0
      ) {
        if (propertyImagesRef.current) {
          propertyImagesRef.current.focus();
        }
        toast.dismiss();
        return toast.error("Please add Properties images.");
      }
      
      if (
        formData.getAll("presentOldPropertyImages").length +
          formData.getAll("propertyImages").length <
        2
      ) {
        if (propertyImagesRef.current) {
          propertyImagesRef.current.focus();
        }
        toast.dismiss();
        return toast.error("Minimum two property images are required.");
      }
    
      if (singleFileProperties === null) {
        formData.append("unitPlan", singleCropImageProp);
      } else {
        formData.append("unitPlan", singleFileProperties);
      }
      // if (mainCroppedBannerImageFile || maincroppedBannerImage) {
      //   formData.append("innerBannerImage", mainCroppedBannerImageFile);
      // } else {

      //   if (maincroppedFileRef.current) {
      //     maincroppedFileRef.current.focus();

      //   }
      //   toast.dismiss();
      //   toast.error("Please upload inner banner image");
      //   return;
      // }

      if (actions === "save") {
        if (!formDataError) {
          editMutation.mutate({ id, formData });
        }
      }
      else if (actions === "preview") {
        if (!formDataError) {
          preview.mutate({ id, formData });
        }

      }

     
    },

    validateOnBlur: false,
    validateOnChange: false,
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = formik;
  useEffect(() => {
    if (Object.values(errors).some((error) => error !== undefined)) {
      const firstEmptyField = Object.entries(errors).find(
        ([, error]) => error !== undefined && !touched[error.path]
      );

      if (firstEmptyField) {
        const [name] = firstEmptyField;

        switch (name) {
          case "propertyName":
            propertyNameRef.current.focus();
            break;
          case "description":
            descriptionRef.current.focus();
            break;

          case "tagline":
            taglineRef.current.focus();
            break;
          case "area":
            areaRef.current.focus();
            break;

          case "slugName":
            slugRef.current.focus();
            break;

          default:
            break;
        }
      }
    }
  }, [errors, touched]);
  const preview = useMutation(querydata.previewEditVilla, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {

      const websiteUrl = `${data.previewURL}?ispropertiespreview=true&uid=${data.encryptedUserId}&propertyId=${id}`;
      window.open(websiteUrl, "_blank");

    },
    onSettled: () => {
      setIsPreviewLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsPreviewLoading(true); // Set loading to true before making the request
    },
  });
  //form update
  const editMutation = useMutation(updateProperty, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);

      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-properties");
      queryClient.invalidateQueries("getSingleProperty");
      setmainCroppedImage(null);
      setmainCroppedImageSorting(null);
      setImage(null);
      setmainCroppedImage(null);
      setBannerImage(null);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const handleRemoveUnitPlanImage = () => {
    setSingleFileProperties("");
    setSingleCropImageProp("");
    setImage("");
    setUnitPlanPdf("");
    setMediaType("");
  };

  const handleRemoveImage = (e, index) => {
    e.preventDefault();
    const updatedImages = [...maincroppedImage];
    const updatedImagesSorting = [...maincroppedImageSorting];
    const updatedAllPropertyImages = [...AllPropertiesImages];
   
    const imagetobedeleted = updatedImages[index];
    if (index >= 0 && index < updatedImages.length) {
      // Remove the image at the specified index
      updatedImages.splice(index, 1);

      // Update the state with the new array of images
      //setmainCroppedFile(updatedImages);
      setmainCroppedImage(updatedImages);
      //setmainCroppedImageSorting(updatedImagesSorting);
      setMultiDeletedImgArray(updatedImages);
      setmainCroppedFile(updatedImages);
      // Check if all images have been deleted
      if (updatedImages.length === 0) {
        // Handle the case where all images have been deleted
        setAllPropertiesImages([]);
      }
    } else {
    }
    if (maincroppedFile && maincroppedFile.currentFileIds) {
      const upadatePropFiles = [...maincroppedFile.currentFileIds];
     
     
      const fileindex = upadatePropFiles.findIndex(
        (record) => record.imagename === imagetobedeleted
      );
    
      if (fileindex >= 0) {
        // Remove the image at the specified index

        upadatePropFiles.splice(fileindex, 1);
        // upadatePropFilesnames.splice(index, 1);
        // setmainCroppedFile(upadatePropFiles);
        setmainCroppedFile((prevData) => ({
          ...prevData,
          currentFileIds: [...upadatePropFiles],
        }));
      } else {
      }
      if (fileindex) {
        let imageurl = upadatePropFiles.find(
          (record) => record.imagename === imagetobedeleted
        );
        if (imageurl) {
          imageurl = imageurl.urlname;
        }
        const fileindexSorting = updatedImagesSorting.findIndex(
          (record) => record === imageurl
        );
        if (fileindexSorting >= 0) {
          // Remove the image at the specified index
          updatedImagesSorting.splice(fileindexSorting, 1);
          setmainCroppedImageSorting(updatedImagesSorting);
        } else {
        }
      }
    }
    const fileindexSorting1 = updatedImagesSorting.findIndex(
      (record) => record === imagetobedeleted
    );
    if (fileindexSorting1 >= 0) {
      // Remove the image at the specified index
      updatedImagesSorting.splice(fileindexSorting1, 1);
      setmainCroppedImageSorting(updatedImagesSorting);
    } else {
    }

    const fileindexSorting2 = updatedAllPropertyImages.findIndex(
      (record) => record.image_path === imagetobedeleted
    );
     if (fileindexSorting2 >= 0) {
      updatedAllPropertyImages.splice(fileindexSorting2, 1);
       setAllPropertiesImages(updatedAllPropertyImages);
    } else {
    }
  };

  ///editor start
  const fullEditorRef = useRef(null);
  // console.log("value of the bed typees ids : ",values.bedTypeId)
  useEffect(() => {
    // Assuming fullEditorRef.current is the ReactQuill instance
    const quill = fullEditorRef.current?.getEditor();

    if (quill) {
      // Listen for text-change event in the Quill editor
      quill.on("text-change", () => {
        const descriptionValue = quill.root.innerHTML; // Get the HTML content of the editor
        setFieldValue("description", descriptionValue); // Update the Formik state
      });
    }
  }, [fullEditorRef.current]);

  //   const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent', 'link'];
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];

  ///editor end

  const [showPropertyImgModal, setShowPropertyImgModal] = useState(false);
  const [showInnerBannerModal, setShowInnerBannerModal] = useState(false);
  const handleClosePropertyImgTagModal = () => {
    setShowPropertyImgModal(false);
  };

  // Function to close Inner Banner modal
  const handleCloseInnerBannerModal = () => {
    setShowInnerBannerModal(false);
  };

  const handleToggleInnerBannerModal = () => {
    setShowInnerBannerModal((prevState) => !prevState);
  };

  const handleTogglePropertyImgModal = () => {
    setShowPropertyImgModal((prevState) => !prevState);
  };
  const handleSaveImageAltTag = (tagType, value) => {
    // Save the image alt tag value to state

    setFieldValue("propertyImgTag", value);
    setShowPropertyImgModal(false);

    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };

  const handleSaveInnerBannerTag = (tagType, value) => {
    // Save the inner banner tag value to state

    setFieldValue("innerBannerImgTag", value);
    setShowInnerBannerModal(false);
    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };

  if (!getSingleProperty.data) {
    return <div>Loading ... </div>;
  }

  const moveImage = (from, to) => {
    const newImages = [...maincroppedImage];
    const newImagesSorting = [...maincroppedImageSorting];

    const AllImages = [...AllPropertiesImages];
    const allmovedImage = AllImages.splice(from, 1)[0];
    const movedImage = newImages.splice(from, 1)[0];
    const movedImageSorting = newImagesSorting.splice(from, 1)[0];
    newImages.splice(to, 0, movedImage);
    newImagesSorting.splice(to, 0, movedImageSorting);
    if (allmovedImage) AllImages.splice(to, 0, allmovedImage);
    else AllImages.splice(to, 0, movedImage);

    setmainCroppedImage(newImages);
    if (movedImageSorting) setmainCroppedImageSorting(newImagesSorting);
    setAllPropertiesImages(AllImages);
  };

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none"
                    to="/manage-properties"
                  >
                    Properties
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Manage Properties
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-items-center">
              <Link
                to="/manage-properties"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0">Manage Properties</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Edit Your Property</h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <form action="#" method="get" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="form-label" htmlFor="PropertyName">
                            Villa/Suite Name{" "}
                            <span className="mandatory">*</span>
                          </label>
                          <div className="mb-4">
                            <input
                              ref={propertyNameRef}
                              type="text"
                              className="form-control py-3 shadow-none px-4"
                              name="propertyName"
                              id="PropertyName"
                              value={values.propertyName}
                              onChange={handlePropertyNameChange}
                              maxLength={200}
                            />
                          </div>
                          {errors.propertyName && touched.propertyName ? (
                            <p className="form-error text-danger">
                              {errors.propertyName}
                            </p>
                          ) : error.propertyName ? (
                            <p className="form-error text-danger">
                              {error.propertyName}
                            </p>
                          ) : null}
                        </div>

                        {/* <!-- /col-md-6 --> */}
                        <div className="col-lg-6">
                          <label className="form-label" htmlFor="PropertyType">
                            Property type
                          </label>
                          <div className="mb-4">
                            <select
                              className="form-control form-select py-3 shadow-none px-4"
                              name="propertyTypeId"
                              id="PropertyType"
                              onChange={handleChange}
                              value={values.propertyTypeId}
                            >
                              <option value="">---Select---</option>
                              {allPropertiesTypes.data &&
                                allPropertiesTypes.data.data.map((option) => (
                                  <option
                                    key={option.property_type_id}
                                    value={option.property_type_id}
                                  >
                                    {option.property_type}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        {/* <div className="col-md-12">

                        <div className="d-flex align-items-center">

                          <label className="form-label mb-0" htmlFor="PropertyUnit">
                            Inner Banner Image <span className="mandatory">*</span>


                          </label>
                          <a title="Add image alt tag" className="btn image-tag" onClick={(e) => handleToggleInnerBannerModal("INNERBANNER")}>

                            <HiPlus />
                          </a>
                          </div>
                          <div className="mb-4">

                            {!maincroppedBannerImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  for="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("BannerImage")
                                  }
                                  ref={maincroppedFileRef}
                                  tabIndex={-1}
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {maincroppedBannerImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a
                                    href={maincroppedBannerImage}
                                    target="_blank"
                                  >
                                    <img
                                      src={maincroppedBannerImage}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) => handleBannerImage(e)}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                            )}
                           
                          </div>
                        </div> */}

                        <div className="col-md-12">
                          <label className="form-label" htmlFor="PropertyUnit">
                            Add Unit Plan
                          </label>
                          <div className="mb-4 uploaded-property-img">
                            {/* {mediaType === "PDF" ? (
                              <>
                                {!unitPlanPdf && (
                                  <label
                                    htmlFor="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal(
                                        "singleUnitPlanImage",
                                        "PDF"
                                      )
                                    }
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Choose from gallery
                                      </button>
                                    </div>
                                  </label>
                                )}
                              </>
                            ) : (
                              <> */}
                            {!singleCropImageProp && (
                              <label
                                htmlFor="UploadCover"
                                className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                onClick={() =>
                                  showCropMediaModal("singleUnitPlanImage")
                                }
                              >
                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                  >
                                    Choose from gallery
                                  </button>
                                </div>
                              </label>
                            )}
                            {/* </>
                            )} */}
                            {/* {mediaType === "PDF" ? (
                              <>
                                {unitPlanPdf && (
                                  <div
                                    className="uploaded-img-view"
                                    style={{
                                      height: "200px",
                                      width: "200px",
                                      position: "relative",
                                    }}
                                  >
                                    <div>
                                      <a
                                        href={
                                          unitPlanPdf?.data?.media_file_path
                                        }
                                        target="_blank"
                                      >
                                        <img
                                          src={pdfIcon}
                                          style={{
                                            width: "68%",
                                            height: "68%",
                                            // objectFit: "cover",
                                          }}
                                        />
                                      </a>
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                        }}
                                      >
                                        <button
                                          className="remove-image-button"
                                          onClick={() =>
                                            handleRemoveUnitPlanImage()
                                          }
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <> */}
                            {singleCropImageProp && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={singleCropImageProp} target="_blank">
                                    <img
                                      src={
                                        singleCropImageProp.endsWith(".pdf")
                                          ? pdfIcon
                                          : singleCropImageProp
                                      }
                                      // src={singleCropImageProp}
                                      style={
                                        singleCropImageProp.endsWith(".pdf")
                                          ? {
                                              width: "68%",
                                              height: "68%",
                                            }
                                          : {
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                            }
                                      }
                                      // style={{
                                      //   width: "100%",
                                      //   height: "100%",
                                      //   objectFit: "cover",
                                      // }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={() =>
                                        handleRemoveUnitPlanImage()
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* </>
                            )} */}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <label className="form-label" htmlFor="Description">
                          Property Description{" "}
                          <span className="mandatory">*</span>
                        </label>
                        <div className="mb-4">
                          <textarea
                            ref={descriptionRef}
                            name="description"
                            id="Description"
                            rows="3"
                            className="form-control py-3 shadow-none px-4"
                            value={values.description}
                            onChange={handleChange}
                            // maxLength={255}
                          ></textarea>
                        </div>
                        {errors.property_description &&
                        touched.property_description ? (
                          <p className="form-error text-danger">
                            {errors.description}
                          </p>
                        ) : error.description ? (
                          <p className="form-error text-danger">
                            {error.description}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-12">
                        <label className="form-label" htmlFor="Description">
                          Tag Line <span className="mandatory">*</span>
                        </label>
                        <div className="mb-4">
                          <textarea
                            ref={taglineRef}
                            name="tagline"
                            id="tagline"
                            rows="3"
                            className="form-control py-3 shadow-none px-4"
                            value={values.tagline}
                            onChange={handleChange}
                            // maxLength={255}
                          ></textarea>
                        </div>
                        {errors.tagline && touched.tagline ? (
                          <p className="form-error text-danger">
                            {errors.tagline}
                          </p>
                        ) : error.tagline ? (
                          <p className="form-error text-danger">
                            {error.tagline}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-12 mb-4">
                        <label
                          className="form-label mb-3"
                          htmlFor="UploadProperty"
                        >
                          Upload property images{" "}
                          <span className="mandatory">*</span>
                          <span className="toolIcon ms-2">
                            <ToolLink
                              title="Minimum 2 images are required and these can be rearranged by dragging and dropping them in desired position."
                              id="t-1"
                            >
                              <BsFillInfoCircleFill />
                            </ToolLink>{" "}
                          </span>
                        </label>
                        <a
                          title="Add image alt tag"
                          className="btn image-tag"
                          onClick={() =>
                            handleTogglePropertyImgModal("PROPERTYIMGTAG")
                          }
                        >
                          <HiPlus />
                        </a>
                        <DndProvider backend={HTML5Backend}>
                          <div className="uploaded-property-img d-flex flex-wrap">
                            {maincroppedImage.length === 0 && (
                              <label
                                htmlFor="UploadCover"
                                className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                onClick={() =>
                                  showCropMediaModal("multipleImageSectionBtn")
                                }
                                ref={propertyImagesRef}
                                tabIndex={-1}
                              >
                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                  >
                                    Upload image from gallery
                                  </button>
                                </div>
                              </label>
                            )}
                            {maincroppedImage.length > 0 &&
                              maincroppedImage.map((imageURL, index) => (
                                <div
                                  key={index}
                                  className="property-img uploaded d-flex justify-content-center align-items-center position-relative"
                                  // style={{
                                  //   height: "200px",
                                  //   width: "200px",
                                  //   position: "relative",
                                  // }}
                                >
                                  <div className="upload-img">
                                    <DragAbleImage
                                      maincroppedImage={imageURL}
                                      moveImage={moveImage}
                                      index={index}
                                      videoImg={videoImg}
                                    />
                                    {/* <a href={imageURL} target="_blank">
                                    <img
                                      // src={imageURL}
                                      src={
                                        imageURL.endsWith(".mp4")
                                          ? videoImg
                                          : imageURL
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a> */}
                                    {/* <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    > */}
                                    <button
                                      className="remove-image-button position-absolute end-0 top-0"
                                      onClick={(e) =>
                                        handleRemoveImage(e, index)
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                    {/* </div> */}
                                  </div>
                                </div>
                              ))}

                            {maincroppedImage.length > 0 && (
                              <div className="property-img d-flex justify-content-center align-items-center position-relative">
                                <div
                                  className="upload-img"
                                  onClick={() =>
                                    showCropMediaModal(
                                      "multipleImageSectionBtn"
                                    )
                                  }
                                >
                                  <i className="fa fa-plus"></i>
                                </div>
                              </div>
                            )}
                          </div>
                        </DndProvider>
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="form-label">360 VIEW LINK </label>
                        <input
                          name="viewlink"
                          value={values.viewlink}
                          onChange={handleChange}
                          type="text"
                          id="Keywords"
                          className="form-control py-3 shadow-none px-4"
                        />
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="form-label" htmlFor="Name">
                          Slug
                          <span className="mandatory">*</span>
                        </label>

                        <div className="bs-example">
                          <input
                            onChange={handleChange}
                            type="text"
                            maxLength={100}
                            name="slugName"
                            value={values?.slugName?.trim()}
                            id="slugName"
                            className="form-control py-3 shadow-none px-4"
                            data-role="tagsinput"
                            onKeyPress={handleKeyPress}
                            ref={slugRef}
                          />
                        </div>
                        {errors.slugName && touched.slugName ? (
                          <p className="form-error text-danger">
                            {errors.slugName}
                          </p>
                        ) : error.slugName ? (
                          <p className="form-error text-danger">
                            {error.slugName}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="form-label">Keywords </label>
                        <input
                          name="keyWords"
                          value={values.keyWords}
                          onChange={handleChange}
                          type="text"
                          id="Keywords"
                          className="form-control py-3 shadow-none px-4"
                        />
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label
                              className="form-label"
                              // htmlFor="OfferName"
                            >
                              Meta Title{" "}
                            </label>
                            <input
                              name="metaTitle"
                              value={values.metaTitle}
                              onChange={handleChange}
                              type="text"
                              id="metaTitle"
                              className="form-control py-3 shadow-none px-4"
                              maxLength={70}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label
                              className="form-label"

                              // htmlFor="OfferName"
                            >
                              Meta Description{" "}
                            </label>
                            <input
                              name="metaDescription"
                              value={values.metaDescription}
                              onChange={handleChange}
                              type="text"
                              id="metaDescription"
                              className="form-control py-3 shadow-none px-4"
                              maxLength={170}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <ul
                          className="nav nav-pills atmos-tabs d-flex flex-wrap pt-3 border-bottom pb-3"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase active"
                              id="specifications-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#specifications-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="specifications-tab-pane"
                              aria-selected="true"
                            >
                              Specifications
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase"
                              id="Amenities-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#Amenities-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="Amenities-tab-pane"
                              aria-selected="false"
                            >
                              Amenities
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase"
                              id="highlight-extra-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#highlight-extra-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="highlight-extra-tab-pane"
                              aria-selected="false"
                            >
                              Signature Features
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase"
                              id="FootNotes-extra-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#FootNotes-extra-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="FootNotes-extra-tab-pane"
                              aria-selected="false"
                            >
                             FootNotes
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content mt-4 mb-0" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="specifications-tab-pane"
                          role="tabpanel"
                          aria-labelledby="specifications-tab"
                          tabIndex="0"
                        >
                          <SpecificationsTabEdit
                            bedDataRef={bedDataRef}
                            initialValues={initialValues}
                            errors={errors}
                            error={error}
                            touched={touched}
                            field={formik.getFieldProps("bedTypeId")}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            setValues={setValues}
                            initialBedTypeData={initialValues.bedTypeId}
                            duplicateIndexes={duplicateIndexes}
                            setDuplicateIndexes={setDuplicateIndexes}
                            areaRef={areaRef}
                          />
                        </div>

                        <div
                          className="tab-pane fade"
                          id="Amenities-tab-pane"
                          role="tabpanel"
                          aria-labelledby="Amenities-tab"
                          tabIndex="0"
                        >
                          <AmenitiesTab1
                            amenitiesMyData={initialValues.amenitiesId}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            error={error}
                            errors={errors}
                            touched={touched}
                            showCropMediaModal={showCropMediaModal}
                            amenityFileData={amenityFileData}
                            setAmenityFile={setAmenityFile}
                            amenityCropImage={amenityCropImage}
                            setAmenityCropImage={setAmenityCropImage}
                            field={formik.getFieldProps("amenitiesId")}
                          />
                        </div>

                        <div
                          className="tab-pane fade"
                          id="highlight-extra-tab-pane"
                          role="tabpanel"
                          aria-labelledby="highlight-extra-tab"
                          tabIndex="0"
                        >
                          <SignatureFeaturesEditTab
                           heading={'Signature Features'}
                           labelHeading={'Edit Feature'}
                           placeholder={'Enter Feature'}
                            field={formik.getFieldProps("features")}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            setValues={setValues}
                            signatureFeaturesData={initialValues.features}
                          />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="FootNotes-extra-tab-pane"
                          role="tabpanel"
                          aria-labelledby="FootNotes-extra-tab"
                          tabIndex="0"
                        >
                          <SignatureFeaturesEditTab
                           heading={'FootNotes'}
                           labelHeading={'Edit FootNote'}
                           placeholder={'Enter FootNote'}
                            field={formik.getFieldProps("footNotes")}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            setValues={setValues}
                            signatureFeaturesData={initialValues.footNotes}
                          />
                        </div>
                      </div>

                      <div className="col-12 text-end mb-2 mt-lg-5 mt-4">
                        <Link
                          to={`/manage-properties`}
                          value="Cancel"
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                        >
                          Cancel
                        </Link>
                        <a
                          // href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setActions('preview');
                            handleSubmit();
                          }}

                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 ms-2"
                        >
                          {isPreviewLoading ? (
                            <div
                              class="spinner-border text-primary"
                              role="status"
                            ></div>
                          ) : (
                            "PREVIEW"
                          )}
                        </a>
                        <Button
                          type="submit"
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 ms-2"
                          onClick={(e) => {
                            e.preventDefault();
                            setActions('save');
                            handleSubmit();
                          }}
                        >
                          {isLoading ? (
                            <div
                              class="spinner-border text-primary"
                              role="status"
                            ></div>
                          ) : (
                            "Update"
                          )}
                        </Button>
                        {/* <input
                          onClick={handleSubmit}
                          type="submit"
                          value="Save"
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 ms-2"
                        /> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Media"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {isAmenityIcon === "amenityIcon" ? (
            <>
              {folderId && albumId && websiteId && (
                <AlbumViewSelectionDynamicAmenityIcon
                  albumId={albumId}
                  websiteId={websiteId}
                  setAlbumId={setAlbumId}
                  setwebsiteId={setwebsiteId}
                  setFolderId={setFolderId}
                  setCropMediaModal1={setCropMediaModal}
                  setAmenityFile={setAmenityFile}
                  setAmenityCropImage={setAmenityCropImage}
                  setting_value={settingValue}
                />
              )}
            </>
          ) : (
            <>
              {folderId && albumId && websiteId && (
                <AlbumViewSelectionForProperties
                  albumId={albumId}
                  websiteId={websiteId}
                  setAlbumId={setAlbumId}
                  setwebsiteId={setwebsiteId}
                  setCropMediaModal1={setCropMediaModal}
                  setmainCroppedImageSorting={setmainCroppedImageSorting}
                  setmainCroppedImage={setmainCroppedImage}
                  setmainCroppedFile={setmainCroppedFile}
                  setSingleFileProperties={setSingleFileProperties}
                  setSingleCropImageProp={setSingleCropImageProp}
                  checkValForSingleImg={checkValForSingleImg}
                  checkMultipleImageSection={checkMultipleImageSection}
                  setMainCroppedBannerImage={setMainCroppedBannerImage}
                  checkValForBannerImage={checkValForBannerImage}
                  setmainCroppedBannerImageFile={setmainCroppedBannerImageFile}
                  setUnitPlanPdf={setUnitPlanPdf}
                  mediaTypeCode={mediaType}
                  setMediaType={setMediaType}
                  // setting_value={getAspectRatio?.data?.data?.setting_value}
                  setting_value={settingValue}
                  setAllPropertiesImages={setAllPropertiesImages}
                  foredit={true}
                />
              )}
            </>
          )}
        </Modal.Body>
      </Modal>

      <ImageTagModal
        showModalImageTagModal={showPropertyImgModal}
        handleCloseImageTagModal={handleClosePropertyImgTagModal}
        initialImageAltTag={values.propertyImgTag}
        tagType="PROPERTYIMGTAG"
        handleSaveImageAltTag={handleSaveImageAltTag}
        inputName="propertyImgTag"
      />

      {/* <ImageTagModal
        showModalImageTagModal={showInnerBannerModal}
        handleCloseImageTagModal={handleCloseInnerBannerModal}
        initialImageAltTag={values.innerBannerImgTag}
        tagType="INNERBANNER"
        handleSaveImageAltTag={handleSaveInnerBannerTag}
        inputName="innerBannerTag"
      /> */}
    </>
  );
}