import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
// import Table from "../../components/Table2";
import {
  getAllPressRelease,
  updatePublished,
  changePublishPressReleaseStatus,
  updatePressReleaseSorting,
} from "../dataService/DataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DeleteModal from "./DeleteModal";
import { toast } from "react-toastify";
import moment from "moment";
import DragTable from "../../components/DragTable";
import { Table } from "../../components/dragTable/Table";
import WithOutDragingTable from "../../components/Table1";
import dummyImage from "../../assets/images/album.png";
const ManagePressReleases = () => {
  const apikey = process.env.REACT_APP_APIKEY;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const handleNavigateToEdit = (id) => {
    navigate(`/manage-press-releases/edit-press-release/${id}`);
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});
  // const [filter, setFilter] = useState(1);

  const handleShowModal = (rowData) => {
    setSelectedRowData(rowData.original.website_press_release_id);
    setSelectedRowNameData(rowData.original.title);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  // const handleCheckboxChange = (row) => {

  //     setSelectedRows((prev) => {
  //         if (prev.includes(row.website_press_release_id)) {
  //             // If the row is already selected, remove it
  //             return prev.filter((selectedRow) => selectedRow !== row.website_press_release_id);
  //         } else {
  //             // If the row is not selected, add it
  //             return [...prev, row.website_press_release_id];
  //         }
  //     });
  // };
  const addMutation = useMutation(updatePublished, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-press-releases");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const handleBulkAction = () => {
    const formData = new FormData();
    formData.append("websiteids", selectedRows.join(","));

    fetch(`${apikey}/press-release/updatePublished`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ websiteids: selectedRows.join(",") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle success
        toast.dismiss();
        toast.success(data.message);
        queryClient.invalidateQueries("getAllPressRelease");
      })
      .catch((error) => {
        toast.dismiss();
        toast.success(error);
      })
      .finally(() => {
        // Any cleanup or additional logic after the request
        queryClient.invalidateQueries("getAllPressRelease");
      });
  };

  function removeHtmlTags(inputString) {
    if (typeof inputString === "string" || inputString instanceof String) {
      return inputString.replace(/<\/?[^>]+(>|$)/g, "");
    }
    return inputString;
  }

  const handleStatus = (e, row) => {
    const isPublished = e.target.value;
    const id = row.original.website_press_release_id;
    row.original.website_press_release_id = isPublished;
    const data = changeApplicationStatusMutate.mutate({ id, isPublished });
  };

  const changeApplicationStatusMutate = useMutation(
    changePublishPressReleaseStatus,
    {
      onError: (error) => {
        toast.dismiss();
        toast.error(error.message);
      },
      onSuccess(data) {
        toast.dismiss();
        toast.success(data.message);
        queryClient.invalidateQueries("getAllPressRelease");
      },
    }
  );

  const columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },

      {
        Header: "Image",
        accessor: "image_path",
        Cell: ({ row }) => (
          <>
            {row.original.image_path ? (
              <img
                src={row.original.image_path}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // Prevents looping
                  currentTarget.src = dummyImage; // Replace with the path to your dummy image
                }}
                width="100"
              ></img>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        Header: "Title",
        Cell: ({ row }) => `${row.original.title}`,
      },
      { Header: "Created From", accessor: "website" },
      {
        Header: "Created Date",
        accessor: "created_on",
        Cell: ({ row }) => (
          <span className="" style={{ width: "200px" }}>
            {moment(row.original.created_on).format(
              "MMM D, YYYY h:mm A [GMT]Z"
            )}
          </span>
        ),
      },
     
      {
        Header: "Status",
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleStatus(e, row)}
            value={row.original.is_published}
            className="form-select"
            style={{ width: "120px" }}
          >
            <option key="1" value="1">
              Published
            </option>
            <option key="0" value="0">
              Unpublished
            </option>
          </select>
        ),
      },

      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() =>
                handleNavigateToEdit(row.original.website_press_release_id)
              }
              className="btn btn-primary btn-sm btn-theme"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              variant="info"
              onClick={() => handleShowModal(row)}
              className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </>
        ),
      },
    ],
    []
  );

  let allPressRelease = useQuery("getAllPressRelease", getAllPressRelease);

  if (!allPressRelease?.data) {
    <div>Loading</div>;
  }
  if (allPressRelease.isLoading) {
    <div>Loading</div>;
  }
  useEffect(() => {
    if (allPressRelease?.data) {
      setData(allPressRelease?.data?.data);
    }
  }, [allPressRelease]);

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };
  const websiteIdFromCookie = getCookie("websiteId");
 
  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <div className="left-header">
              <h4 className="fw-normal m-0">Manage Press Release</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">All Press Releases</h5>
                  <div className="right-actions">
                    <Link
                      to="/manage-press-releases/create-press-release"
                      className="btn btn-primary btn-theme fw-semibold text-uppercase ms-2 px-3 py-2"
                    >
                      Add New
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="tabledata">
                    <div className="table-responsive">
                      {/* {
                        allPressRelease?.data?.data && (
                          <>
                          {websiteIdFromCookie ==='3'?(
                            <>

                            <WithOutDragingTable
                            
                      data={allPressRelease?.data?.data}
                      columns={columns}
                     
                    />
                          </>
                          ):(
                            <>
                            <Table
                            data={data}
                            setData={setData}
                            columns={columns}
                            isShowingHeading={false}
                            updateSortingTable={updatePressReleaseSorting}
                            componentName={"pressrelease"}
                          />
                          </>)}
                          
                          </>
                        ) } */}
                        {allPressRelease.isLoading || !allPressRelease?.data ? (
                        <div className="text-center">Loading...</div>
                      ) : data.length === 0 ? (
                        <div className="text-center">No Data Available</div>
                      ) : (
                        <Table
                          data={data}
                          setData={setData}
                          columns={columns}
                          isShowingHeading={false}
                          updateSortingTable={updatePressReleaseSorting}
                          componentName={"pressrelease"}
                        />
                      )}
                      <DeleteModal
                        show={showModal}
                        handleClose={handleCloseModal}
                        rowData={selectedRowData}
                        rowNameData={selectedRowNameData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePressReleases;
